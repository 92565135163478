import React from "react"
import styles from "./styles/home.module.css"
import { useEffect, useState } from "react"
import { getFirestore, collection, query, getDocs } from "firebase/firestore"
import { firebaseApp } from "../../App"
import { Sticker } from "schema/dist/src/sticker"
import { Poster } from "schema/dist/src/poster"
import { OrderDetails } from "schema/dist/src/order"
import { ProfileData } from "schema/dist/src/user"

interface StatComponentProps {
  title: string
  value: string | number
  logo: string
}

const StatComponent: React.FC<StatComponentProps> = ({ title, logo, value }): JSX.Element => {
  return (
    <div className={styles.StatComponent}>
      <div className={styles.StatsComponentImage + " " + logo} />
      <div className={styles.StatsComponentTitle}> {title} </div>
      <div className={styles.StatsComponentValue}> {value} </div>
    </div>
  )
}

interface StatContainerComponentProps {}

export const StatContainerComponent: React.FC<StatContainerComponentProps> = (): JSX.Element => {
  const db = getFirestore(firebaseApp)

  const [userCount, setUserCount] = useState(0)
  const [regUserCount, setRegUserCount] = useState(0)
  const [anonUserCount, setAnonUserCount] = useState(0)

  const [createdCount, setCreatedCount] = useState(0)
  const [publicCount, setPublicCount] = useState(0)

  const [totalStickerCount, setTotalStickerCount] = useState(0)
  const [linkedStickerCount, setLinkedStickerCount] = useState(0)

  const [totalOrderCount, setTotalOrderCount] = useState(0)
  const [totalSales, setTotalSales] = useState("")

  //USER QUERY
  useEffect(() => {
    const orders = query(collection(db, "userData"))
    getDocs(orders)
      .then((userDocs) => {
        // setCreatedCount(usersDocs.docs.length)

        let usersDetails: ProfileData[] = []

        userDocs.forEach((doc) => {
          const userData = doc.data() as ProfileData
          usersDetails.push(userData)
        })

        setUserCount(usersDetails.length)

        const anonUsers = usersDetails.filter((value, index) => {
          return value.userEmail === ""
        })
        setAnonUserCount(anonUsers.length)

        setRegUserCount(usersDetails.length - anonUsers.length)

        let userEmails: string[] = []

        usersDetails.map((profile: ProfileData, index: number, array: ProfileData[]) => {
          let email = profile.userEmail
          if (email === "" || email === null) return
          userEmails.push(email)
        })

        // setUserEmails(userEmails)
      })
      .catch((error) => {
        console.log("Error getting document:", error)
      })
  }, [db])

  //USERS QUERY
  useEffect(() => {
    const users = query(collection(db, "userPosters"))
    getDocs(users)
      .then((usersDocs) => {
        // setCreatedCount(usersDocs.docs.length)

        let userPosters: Poster[] = []
        usersDocs.forEach((posterDocument) => {
          const posterData = posterDocument.data() as Poster
          userPosters.push(posterData)
        })

        setCreatedCount(userPosters.length)

        const publicPosters = userPosters.filter((value, index) => {
          return value.isPublic === true
        })
        setPublicCount(publicPosters.length)
      })
      .catch((error) => {
        console.log("Error getting document:", error)
      })
  }, [db])

  //STICKER QUERY
  useEffect(() => {
    const stickers = query(collection(db, "userStickers"))
    getDocs(stickers)
      .then((usersDocs) => {
        // setCreatedCount(usersDocs.docs.length)

        let userStickers: Sticker[] = []
        usersDocs.forEach((stickerDocument) => {
          const stickerData = stickerDocument.data() as Sticker
          userStickers.push(stickerData)
        })

        setTotalStickerCount(userStickers.length)
        const linkedStickers = userStickers.filter((value, index) => {
          return value.posterUid === ""
        })
        setLinkedStickerCount(linkedStickers.length)
      })
      .catch((error) => {
        console.log("Error getting document:", error)
      })
  }, [db])

  //ORDERS QUERY
  useEffect(() => {
    const orders = query(collection(db, "orderDetails"))
    getDocs(orders)
      .then((orderDocs) => {
        // setCreatedCount(usersDocs.docs.length)

        let orderDetails: OrderDetails[] = []
        let salesTotal = 0

        orderDocs.forEach((doc) => {
          const orderData = doc.data() as OrderDetails
          orderDetails.push(orderData)

          salesTotal += orderData.total
        })

        setTotalOrderCount(orderDetails.length)
        setTotalSales("$" + (salesTotal / 100).toString())
      })
      .catch((error) => {
        console.log("Error getting document:", error)
      })
  }, [db])

  return (
    <div className={styles.StatsContainer}>
      <div className={styles.StatGroupTitle}> User </div>
      <StatComponent title="Total Users" logo={styles.UsersIcon} value={userCount} />
      <StatComponent title="Regular Users" logo={styles.RegularUserIcon} value={regUserCount} />
      <StatComponent title="Anon Users" logo={styles.AnonIcon} value={anonUserCount} />
      <div className={styles.StatGroupTitle}> ART </div>
      <StatComponent title="Created" logo={styles.CreatedIcon} value={createdCount} />
      <StatComponent title="Public" logo={styles.PublicCreatedIcon} value={publicCount} />

      <StatComponent title="Stickers" logo={styles.StickerIcon} value={totalStickerCount} />
      <StatComponent title="Linked Stickers" logo={styles.LinkedStickerIcon} value={linkedStickerCount} />
      <div className={styles.StatGroupTitle}> SHOP </div>
      <StatComponent title="Orders" logo={styles.OrdersIcon} value={totalOrderCount} />
      <StatComponent title="Sales" logo={styles.SalesIcon} value={totalSales} />
      <div className={styles.StatGroupTitle}> Download Stats </div>
    </div>
  )
}
