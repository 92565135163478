import { collection, doc, getFirestore, onSnapshot } from "firebase/firestore"
import React, { useContext, useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { alias, firebaseApp } from "../App"
import { returnPageKey } from "../auth"
import { CartItem } from "schema/dist/src/cart"
import styles from "./NavBar.module.css"
import { AppContext } from "../App"
import { User } from "firebase/auth"

export const Main: React.FC<{}> = (): JSX.Element => {
  const nav = useNavigate()
  const { user, navType } = useContext(AppContext)
  const [lower, setLower] = useState(false)
  function ChangeOnScroll() {
    if (window.scrollY >= 200) {
      setLower(true)
    } else {
      setLower(false)
    }
  }

  let className = styles.root
  if (navType === "sleek") {
    className += " " + styles.SleekNavBar
  } else if (navType === "sticky") {
    className += " " + styles.StickerBar

    if (lower) {
      className += " " + styles.BlurBar
    }
  }

  window.addEventListener("scroll", ChangeOnScroll)

  return (
    <div className={className}>
      <div
        className={styles.Logo}
        onClick={() => {
          nav("/")
        }}
      ></div>
      {alias === "prod" ? null : alias}
      <div
        className={styles.LogoText}
        onClick={() => {
          nav("/")
        }}
      ></div>

      <CartButton user={user} />
      <ProfileButton user={user} />
      <SignInButton user={user} />
    </div>
  )
}
export default Main

export const SignInButton: React.FC<{ user: User | null }> = ({ user }): JSX.Element => {
  const nav = useNavigate()
  const currentPath = useLocation().pathname
  let ButtonText = "signin"

  if (user !== null && !user.isAnonymous) {
    ButtonText = "signout"
  }

  function HandleClick() {
    if (user !== null && !user.isAnonymous) {
      nav(`/signout?${returnPageKey}=${currentPath}`)
    } else {
      nav(`/signin?${returnPageKey}=${currentPath}`)
    }
  }

  return (
    <div className={styles.SignInButton} onClick={HandleClick}>
      {ButtonText}
    </div>
  )
}

export const CartButton: React.FC<{ user: User | null }> = ({ user }): JSX.Element => {
  const nav = useNavigate()

  const [cartList, setCartList] = useState<CartItem[]>([])

  useEffect(() => {
    if (user === null) {
      return
    }
    const db = getFirestore(firebaseApp)
    const posterRefParent = doc(db, "userData", user.uid)
    const newPosterCol = collection(posterRefParent, "cartItems")
    const done = onSnapshot(newPosterCol, (snap) => {
      const CartItem = snap.docs.map((doc) => {
        return doc.data() as CartItem
      })

      setCartList([...CartItem])
    })

    return () => {
      done()
    }
  }, [user])

  let totalItems = 0
  cartList.forEach((item) => {
    totalItems += item.count
  })

  if (user === null) {
    return <></>
  }

  return (
    <div
      className={styles.CartButton}
      onClick={() => {
        nav("/cart")
      }}
    >
      <div className={styles.CartButtonIcon} />
      {totalItems > 0 ? <div className={styles.CartButtonCount}>{totalItems}</div> : null}
    </div>
  )
}

export const ProfileButton: React.FC<{
  user: User | null
}> = ({ user }): JSX.Element => {
  const nav = useNavigate()

  if (user === null || user.isAnonymous) {
    return <></>
  }

  return (
    <div
      className={styles.CartButton}
      onClick={() => {
        nav("/account")
      }}
    >
      <div className={styles.ProfileButtonIcon} />
    </div>
  )
}
