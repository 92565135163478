import { Product, ProductVariant } from "./product"
export interface CartItem {
  uid: string
  productUid: string
  productImageUrl: string
  productImageUrlHD: string
  orderImageUrlLD: string
  orderImageUrlHD: string
  title: string
  productType: "userPoster" | "shopProduct"
  count: number
  productVariant: ProductVariant
}

/**
 * posterToCartItem uses a poster data to create a cart item then will be used in a order
 * @param data - the poster data to be used in creating cart data
 * @param customerUId
 * @param variant
 * @param count
 * @returns
 */
export function ProductToCartItem(
  data: Product,
  cartUID: string,
  variant: ProductVariant,
  count: number,
  productType: "userPoster" | "shopProduct"
): CartItem {
  const newCard: CartItem = {
    uid: cartUID,
    productUid: data.uid,
    productImageUrl: data.imageLDURL,
    productImageUrlHD: data.imageHDURL,
    orderImageUrlLD: data.imageLDURL,
    orderImageUrlHD: data.imageHDURL,
    title: data.title,
    productType: productType,
    count: count,
    productVariant: variant,
  }
  return newCard
}
