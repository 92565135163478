import { Route, Routes } from "react-router-dom"
import styles from "./index.module.css"
import Landing from "./landing"
import TobennaWes from "./tobennawes"
import Product from "./product"
import Burnaboy from "./burnaboy"
import { useEffect } from "react"

export const Main: React.FC<{}> = (): JSX.Element => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className={styles.root}>
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="tobennawes" element={<TobennaWes />} />
        <Route path="burnaboy" element={<Burnaboy />} />
        <Route path="product/:productID" element={<Product />} />
      </Routes>
    </div>
  )
}
export default Main
