import { Timestamp } from "firebase/firestore"

export interface Category {
  uid: string
  imageURL: string
  isActive: boolean
  count: number
  timeStamp: Timestamp
  title: string
  pageID: string
}

export function CreateCategory(uid: string, timeStamp: Timestamp): Category {
  return {
    uid: uid,
    imageURL: "",
    isActive: false,
    count: 0,
    timeStamp: timeStamp,
    title: "",
    pageID: "",
  }
}
