import { Timestamp } from "firebase/firestore"

export interface Product {
  title: string
  uid: string
  posterUid: string
  category: string
  price: number
  productDescription: string
  imageHDURL: string
  imageLDURL: string
  isActive?: boolean
  timeStamp: Timestamp
  favorite: boolean
  videoURL: string
  relatedImagesURL: string[]
  productImagesURL: string[]
  productVariantList: ProductVariant[]

  /**
   * typeId is for the type of product
   * This is not related to product type
   * 1 = Regular Poster
   * 2 = AR Poster
   * 3 = Accessory
   */
  typeId: 1 | 2 | 3
  nftLink: string
  keywords: string[]

  /**
   * this is used for instances when the user has to do some work before it can be added to cart
   */
  studioID?: string
}

export interface ProductVariant {
  uid: string
  size: string
  price: number
  count?: number // number or items contained in the product variant
  productList?: string[] // the list of products contained in the product variant
  productType?: string
  /**
   *
   * @param printfulVariantID
   * @example
   * 1 = {24x28} poster
   * 1349 = 12" x 16"
   */

  printfulVariantID?: number // the variant ID of the printful version of the product
  printfulWarehouseID?: number // the printfulWarehouse ID of the printful product
  studioID?: string
  studioSessionID?: string
}

export function isProductVariantActive(a: ProductVariant, b: ProductVariant): boolean {
  return a.size === b.size && a.studioSessionID === b.studioSessionID && a.price === b.price && a.studioID === b.studioID
}

export function CreateProductVariant(uid: string): ProductVariant {
  return {
    uid: uid,
    size: "",
    price: 0.0,
  }
}

export function CreateDefaultProduct(uid: string, timeStamp: Timestamp): Product {
  return {
    title: "",
    uid: uid,
    posterUid: "",
    category: "",
    price: 0,
    productDescription: "",
    imageHDURL: "",
    imageLDURL: "",
    isActive: false,
    timeStamp: timeStamp,
    favorite: false,
    videoURL: "",
    relatedImagesURL: [],
    productImagesURL: [],
    productVariantList: [],
    typeId: 1,
    nftLink: "",
    keywords: [],
  }
}
