import React, { useContext, useEffect, useState } from "react"
import styles from "./index.module.css"
import { getDoc } from "firebase/firestore"
import { getFirestore, doc, collection } from "firebase/firestore"
import { AppContext, firebaseApp } from "../../App"
import { Product, ProductVariant } from "schema/dist/src/product"
import { useNavigate, useParams } from "react-router-dom"
import ImagePlaceHolder from "../../assets/mona.jpeg"
import {
  AddSessionToCart,
  CreateStudioSession,
  CreationSteps,
  FrequentList,
  IncludedList,
  IntroBody,
  IntroVideo,
  ValidateAssetSize,
  VariantContainer,
} from "../shared/shared"
import { PosterData, UploadToAssetLocation } from "../shared/schema"
import { StudioSession, StudioSessionAssets } from "schema/dist/src/studio"

import { CreationControllerStart, UploadPicture, UploadVideo } from "../polaroid"
import { Poster3D } from "./poster3D"
import { SizeToDimensions } from "../../shop/product"
import { ValidateUserToAnon } from "../../auth"

import MonaVideo from "../../assets/video/mona_2.mp4"
import { PosterViewState } from "../../shop/product/frame"

const studioID = "poster"
export const Main: React.FC<{}> = (): JSX.Element => {
  const { user } = useContext(AppContext)
  const [studioProduct, setStudioProduct] = useState<Product>()
  const [selectedVariant, setSelectedVariant] = useState<ProductVariant>()
  const [session, setSession] = useState<StudioSession>()
  const navigate = useNavigate()
  const { sessionID } = useParams<{ sessionID: string }>()

  // ON PAGE LOAD  //
  useEffect(() => {
    const db = getFirestore(firebaseApp)
    if (sessionID !== undefined) {
      const sessionCollRef = collection(db, "studioSessions")
      let sessionRef = doc(sessionCollRef, sessionID)
      getDoc(sessionRef)
        .then((doc) => {
          if (!doc.exists()) {
            return
          }
          const newSession = doc.data() as StudioSession
          setSession(newSession)
        })
        .catch((err) => {})
    } else {
      setSession(undefined)
    }
  }, [sessionID])

  useEffect(() => {
    const db = getFirestore(firebaseApp)

    if (selectedVariant === undefined || session === undefined) return

    const sessionData: StudioSessionAssets[] = []
    const posterRef = doc(collection(db, "userPosters"))
    sessionData.push({
      videoURL: session.sessionData[0]?.videoURL ?? "",
      imageURL: session.sessionData[0]?.imageURL ?? "",
      posterUID: session.sessionData[0]?.posterUID ?? posterRef.id,
    })

    session.sessionData = sessionData
    session.productVariant = selectedVariant
    setSession(JSON.parse(JSON.stringify(session)))
  }, [selectedVariant])

  return (
    <div className={styles.root}>
      <div className={styles.VideoTop}>
        <IntroVideo videoURL={MonaVideo} backdrop={ImagePlaceHolder}>
          <IntroBody
            title="Create your Revivar Poster"
            subTitle="Custom Live Poster Prints with an AR Video"
            session={session}
            onClick={() => {
              if (selectedVariant === undefined) return
              CreateStudioSession(selectedVariant, user?.uid ?? "", "poster")
                .then((session) => {
                  console.log("done")
                  setSession(session)
                  navigate({
                    pathname: "/studio/poster/" + session.uid,
                  })
                  window.scrollTo({ top: window.innerHeight, behavior: "smooth" })
                })
                .catch((err) => {})
            }}
          />
        </IntroVideo>
      </div>
      <VariantContainer
        setProduct={setStudioProduct}
        product={studioProduct}
        setSelectedVariant={setSelectedVariant}
        selectedVariant={selectedVariant}
        studioID={studioID}
        studioSession={session}
      />
      <br />
      <PosterPreview session={session} setSession={setSession} product={studioProduct} sessionID={sessionID}></PosterPreview>
      <IncludedList />
      <br />
      <br />
      <FrequentList />
    </div>
  )
}
export default Main

interface PosterPreviewProps {
  session: StudioSession | undefined
  setSession: (session: StudioSession) => void
  product: Product | undefined
  sessionID: string | undefined
}

export const PosterPreview: React.FC<PosterPreviewProps> = ({ session, product, sessionID, children }): JSX.Element => {
  const [posterData, setPosterData] = useState<PosterData>()
  const [playVideo, setPlayVideo] = useState(true)
  const [viewState, setViewState] = useState<PosterViewState>("normal")

  const [showVideo, setShowVideo] = useState(true)
  const [previewTime, setPreviewTime] = useState<number>()
  const [creationStep, setCreationStep] = useState(0)
  const [isUploading, setIsUploading] = useState(false)
  const [uploadingText, setUploadingText] = useState("")
  const nav = useNavigate()
  const { user } = useContext(AppContext)

  useEffect(() => {
    if (session === undefined) return

    const data: PosterData = {
      videoURL: session?.sessionData[0]?.videoURL ?? "",
      imageURL: session?.sessionData[0]?.imageURL ?? "",
      uid: session?.sessionData[0]?.posterUID ?? "",
    }

    setPosterData(data)
    setPlayVideo(false)
  }, [session])

  useEffect(() => {
    if (creationStep === 2) {
      setShowVideo(false)
    }
  }, [creationStep])

  if (session === undefined || posterData === undefined) return <> {children}</>

  let playButtonClass = styles.playButton
  if (playVideo) {
    playButtonClass += " " + styles.pausePlayButton
  }

  let ExpandButtonClass = styles.ExpandButton
  if (viewState === "expand") {
    ExpandButtonClass += " " + styles.CollapseButton
  } else if (viewState === "compare") {
    ExpandButtonClass += " " + styles.CompareButton
  }

  let showAddToCart = true
  session.sessionData.forEach((sesh) => {
    if (sesh.videoURL === "" || sesh.imageURL === "" || sesh.posterUID === "" || isUploading) {
      showAddToCart = false
    }
  })

  if (!session?.editable) {
    return (
      <div className={styles.PosterPreview}>
        <Poster3D
          poster={posterData}
          playVideo={playVideo}
          previewTime={previewTime}
          showVideo={showVideo}
          ratio={SizeToDimensions(session.productVariant.size)}
          viewState={viewState}
          setPlay={setPlayVideo}
        >
          Hover to play
          <div className={styles.PlayButtonStack}>
            <div
              className={playButtonClass}
              onClick={() => {
                const newState = !playVideo
                setPlayVideo(newState)
                setShowVideo(newState)
              }}
            />
            <div
              className={ExpandButtonClass}
              onClick={() => {
                if (viewState === "expand") {
                  setViewState("compare")
                } else if (viewState === "compare") {
                  setViewState("normal")
                } else {
                  setViewState("expand")
                }
              }}
            />
          </div>
          <br />
          <div className={styles.ControlsStacker}>
            <CreationControllerStart
              onClick={() => {
                nav("/cart")
              }}
              isHidden={!showAddToCart}
              text={"Back to Cart"}
            />
          </div>
        </Poster3D>
      </div>
    )
  }

  return (
    <>
      {children}
      <div className={styles.PosterPreview}>
        <Poster3D
          poster={posterData}
          playVideo={playVideo}
          previewTime={previewTime}
          showVideo={showVideo}
          ratio={SizeToDimensions(session.productVariant.size)}
          viewState={viewState}
          setPlay={setPlayVideo}
        >
          Hover to play
          <div className={styles.PlayButtonStack}>
            <div
              className={playButtonClass}
              onClick={() => {
                const newState = !playVideo
                setPlayVideo(newState)
                setShowVideo(newState)
              }}
            />
            <div
              className={ExpandButtonClass}
              onClick={() => {
                if (viewState === "expand") {
                  setViewState("compare")
                } else if (viewState === "compare") {
                  setViewState("normal")
                } else {
                  setViewState("expand")
                }
              }}
            />
          </div>
        </Poster3D>

        <div className={styles.PosterPreviewAction}>
          <br />
          {uploadingText}
          <div className={styles.ControlsStacker}>
            <UploadVideo
              onFileSelected={(a: File) => {
                const valid = ValidateAssetSize(a)

                if (!valid.valid) {
                  setUploadingText(valid.reason)
                  return
                }

                setIsUploading(true)
                const sessionData = session.sessionData[0]
                const fileName = session.sessionData[0].posterUID + "video"
                posterData.videoURL = "loading"
                setPosterData(posterData)
                UploadToAssetLocation(sessionData, fileName, session, a, "video", (a) => {
                  setUploadingText(`Uploading ${a.toFixed(1)}%`)
                })
                  .then((ref) => {
                    posterData.videoURL = ref
                    posterData.rerender = !posterData.rerender
                    setPosterData(posterData)
                    setCreationStep(1)
                    console.log("Done well")
                    setPlayVideo(true)
                  })

                  .catch(() => {
                    setUploadingText("Error uploading File")
                  })
                  .finally(() => {
                    setIsUploading(false)
                    setUploadingText("")
                  })
              }}
              isHidden={creationStep !== 0}
              showNext={(posterData?.videoURL ?? "") !== ""}
              showBack={false}
              onNexClicked={(e) => {
                setCreationStep(e ? 1 : 0)
              }}
              disabled={isUploading}
            />
            <UploadPicture
              videoUrl={posterData?.videoURL ?? ""}
              onFileSelected={(a: File | Blob) => {
                setIsUploading(true)
                posterData.imageURL = "loading"
                setPosterData(posterData)
                UploadToAssetLocation(
                  session.sessionData[0],
                  session.sessionData[0].posterUID + "image",
                  session,
                  a,
                  "image",
                  (a) => {
                    setUploadingText(`Uploading ${a.toFixed(1)}%`)
                  }
                )
                  .then((ref) => {
                    posterData.imageURL = ref
                    posterData.rerender = !posterData.rerender
                    setPosterData(posterData)
                    setShowVideo(false)
                    setCreationStep(2)
                  })
                  .catch(() => {
                    setUploadingText("Error uploading File")
                  })
                  .finally(() => {
                    setIsUploading(false)
                    setUploadingText("")
                  })
              }}
              onSlide={(e) => {
                setShowVideo(true)
                setPlayVideo(false)
                setPreviewTime(e)
              }}
              isHidden={creationStep !== 1}
              showNext={(posterData?.imageURL ?? "") !== ""}
              showBack={true}
              onNexClicked={(e) => {
                setCreationStep(e ? 2 : 0)
              }}
              disabled={isUploading}
              fileRatio={SizeToDimensions(session.productVariant.size)}
            />

            {creationStep === 2 ? (
              <div className={styles.DoubleButtonStack}>
                <CreationControllerStart
                  onClick={() => {
                    setCreationStep(1)
                  }}
                  isHidden={creationStep !== 2}
                  text={"Back"}
                  isInverted
                />
              </div>
            ) : null}
          </div>

          <CreationSteps steps={creationStep} />

          {showAddToCart ? (
            <div className={styles.ControlsStacker + " " + styles.AddToCartSpacer}>
              <CreationControllerStart
                onClick={() => {
                  if (product === undefined || sessionID === undefined) return

                  ValidateUserToAnon(user)
                    .then((actualUser) => {
                      return AddSessionToCart(actualUser.uid, session, studioID, sessionID, product)
                    })
                    .then(() => {
                      return nav("/cart")
                    })
                    .catch((error: any) => {
                      setUploadingText("Error occurred")
                    })
                }}
                isHidden={!showAddToCart}
                text={"Add to Cart"}
              />
            </div>
          ) : null}
        </div>
      </div>
    </>
  )
}

type CodeLocation = "Top Right" | "Top Left" | "Bottom Right" | "Bottom Left"
interface SelectCodeLocationProps {
  location: CodeLocation
  setLocation: (location: CodeLocation) => void
}
export const SelectCodeLocation: React.FC<SelectCodeLocationProps> = ({ location, setLocation }): JSX.Element => {
  const LocationList: CodeLocation[] = ["Top Right", "Top Left", "Bottom Right", "Bottom Left"]
  return (
    <div className={styles.SelectCodeLocation}>
      Select QR Location
      <div className={styles.SelectCodeLocationButtonList}>
        {LocationList.map((loc: CodeLocation) => {
          let className = styles.SelectCodeLocationButton
          if (location === loc) {
            className += " " + styles.SelectCodeLocationButtonSelected
          }
          return (
            <div
              className={className}
              onClick={() => {
                setLocation(loc)
              }}
            >
              {loc}
            </div>
          )
        })}
      </div>
    </div>
  )
}
