import React from "react"
import styles from "./index.module.css"
import { Suspense } from "react"
import { Canvas } from "@react-three/fiber"
import { OrbitControls } from "@react-three/drei"
import { PosterData } from "../shared/schema"
import { PosterContainer, PosterViewState } from "../../shop/product/frame"

interface Polaroid3DProps {
  poster: PosterData
  playVideo: boolean
  showVideo: boolean
  previewTime?: number
  ratio?: number
  viewState: PosterViewState
  setPlay: (a: boolean) => void
}

export const Poster3D: React.FC<Polaroid3DProps> = ({
  poster,
  playVideo,
  showVideo,
  previewTime,
  children,
  ratio = 0.8,
  viewState,
  setPlay,
}): JSX.Element => {
  return (
    <div className={styles.PosterImmersedContainer}>
      <Canvas dpr={[1, 2]} camera={{ fov: 400, position: [0, 0, 180] }}>
        <directionalLight intensity={1} />
        <ambientLight intensity={1} />
        {/* <OrbitControls makeDefault enableZoom={false} enabled={false} /> */}
        <Suspense fallback={null}>
          <PosterContainer
            imageURL={poster.imageURL}
            videoURL={poster.videoURL}
            ratio={ratio}
            play={playVideo}
            previewTime={previewTime}
            showVideo={showVideo}
            rotate={true}
            viewState={viewState}
            setPlay={setPlay}
          />
        </Suspense>
      </Canvas>
      {children}
    </div>
  )
}
