import "./styles/App.css"
import { Routes, Route, useLocation, useNavigate } from "react-router-dom"
import NavBar from "./navBar/NavBar"
import Home from "./landing/home"
import About from "./landing/about"
import NotFound from "./landing/404"
import Activate from "./landing/activate"
import Contact from "./landing/contactus"

import Sticker from "./poster/sticker"

import Cart from "./cart"
import Account from "./account"
import Shop from "./shop"
import Legal from "./landing/legal/legal"
import Terms from "./landing/legal/terms"
import Asset from "./poster"
import GenerateQR from "./create/generateQR"
import Create from "./create/create"
import { SignIn, SignOut, SignUp, ResetPassword, returnPageKey } from "./auth"
import OverView from "./admin"
import { initializeApp } from "firebase/app"
import { getAuth, User } from "firebase/auth"
import { firebaseConfigStaging, firebaseConfigDev, firebaseConfigProd } from "schema/dist/src/firebase"
import React, { useEffect, useState } from "react"

export let setAppConfig = firebaseConfigDev
export let previewAppWebsite = ""

// https://tacomanator.medium.com/environments-with-create-react-app-7b645312c09d
export const alias = process.env.REACT_APP_ENV
if (alias === "prod") {
  setAppConfig = firebaseConfigProd
  previewAppWebsite = "https://preview.revivar.app"
} else if (alias === "dev") {
  setAppConfig = firebaseConfigDev
  previewAppWebsite = "https://preview.revivar.dev"
} else if (alias === "staging") {
  setAppConfig = firebaseConfigStaging
  previewAppWebsite = "https://preview.staging.revivar.dev"
} else {
  console.error("PLEASE SET APP ENVIRONMENT")
}

export let isUsingEmulator = true
export let isUsingLocalPreview = true

export const LocalIP = "0.0.0.0"
export const FunctionsLocalPort = 5033
var env = process.env.NODE_ENV || "development"

if (env === "production") {
  isUsingEmulator = false
  isUsingLocalPreview = false
}

if (isUsingLocalPreview) {
  previewAppWebsite = "http://localhost:3100"
}

export const firebaseApp = initializeApp(setAppConfig)

export function setTabTitle(title: string) {
  document.title = title
}

type NavBarType = "bar" | "sleek" | "sticky"
interface AppContextType {
  user: User | null
  setUser: (user: User) => void
  navType: NavBarType
  setNavType: (type: NavBarType) => void
  userToken: string
}

const auth = getAuth(firebaseApp)

export const AppContext = React.createContext<AppContextType>({
  user: auth.currentUser,
  userToken: "",
  setUser: () => {},
  setNavType: () => {},
  navType: "bar",
})

export const App: React.FC<{}> = (): JSX.Element => {
  const auth = getAuth(firebaseApp)
  const [authState, setAuthState] = useState(auth.currentUser)
  const [useToken, setUserToken] = useState("")
  const [navBarType, setNavBarType] = useState<NavBarType>("bar")

  const nav = useNavigate()
  const loc = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  //github.com/firebase/functions-samples/blob/main/delete-unused-accounts-cron/functions/index.js
  useEffect(() => {
    const done = auth.onAuthStateChanged((user) => {
      setAuthState(auth.currentUser)
      if (auth.currentUser !== null) {
        auth.currentUser.getIdToken().then((token) => {
          document.cookie = "__session=" + token + ";max-age=3600"
          setUserToken(token)
        })
      } else {
        // ADD CART?
        // TODO:: CAN ADD TO CART BUT
        if (loc.pathname.includes("/account")) {
          // CHECK IF ANON
          nav(`/signin?${returnPageKey}=${loc.pathname}`)
        }
        if (loc.pathname.includes("/cart")) {
          // CHECK IF ANON
          nav(`/signin?${returnPageKey}=${loc.pathname}`)
        }
        if (loc.pathname.includes("/admin")) {
          nav(`/signin?${returnPageKey}=${loc.pathname}`)
        }
      }
    })

    return () => {
      done()
    }
  }, [auth, loc, nav])

  return (
    <AppContext.Provider
      value={{
        user: authState,
        setUser: setAuthState,
        userToken: useToken,
        navType: navBarType,
        setNavType: setNavBarType,
      }}
    >
      <div className="App">
        <Routes>
          <Route path="/" element={<Home />} />

          {/* STATIC LANDING PAGES */}
          <Route path="about" element={<About />} />
          <Route path="legal" element={<Legal />} />
          <Route path="terms" element={<Terms />} />

          {/* EVERYTHING THAT HAS TO DO WITH AUTHENTICATION */}
          <Route path="signin" element={<SignIn />} />
          <Route path="register" element={<SignUp />} />
          <Route path="signout" element={<SignOut />} />
          <Route path="resetPassword" element={<ResetPassword />} />

          {/* EVERYTHING THAT HAS A NAV BAR*/}
          <Route path="*" element={<Navigate />} />
        </Routes>
      </div>
    </AppContext.Provider>
  )
}

export default App

export const Navigate: React.FC<{}> = (): JSX.Element => {
  return (
    <div className="App">
      <NavBar />

      <Routes>
        <Route path="activate" element={<Activate />} />
        <Route path="sticker/:stickerID" element={<Sticker />} />

        {/* SHOP HAS ALL ROUTS FOR PRODUCTS ETC */}
        <Route path="shop/*" element={<Shop />} />
        <Route path="asset/:posterID" element={<Asset />} />
        <Route path="cart/*" element={<Cart />} />
        <Route path="account/*" element={<Account />} />

        {/* WHERE DESIGN WORK IS DONE*/}
        <Route path="studio/*" element={<Create />} />
        <Route path="generateqr" element={<GenerateQR />} />

        {/* ADMIN */}
        <Route path="admin/*" element={<OverView />} />
        <Route path="contactus" element={<Contact />} />

        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  )
}
