import styles from "./index.module.css"

import { useEffect, useRef, useState } from "react"
import loadingImage from "./../../assets/loadingImage.png"
import { useNavigate } from "react-router-dom"
import { firebaseApp } from "../../App"
import React from "react"
import { collection, getDocs, getFirestore } from "firebase/firestore"
import { Product } from "schema/dist/src/product"
import { getDownloadURL, getStorage, ref } from "firebase/storage"
import { CreateNewShopProducts } from "./create"

export const ProductList: React.FC<{}> = (): JSX.Element => {
  const [shopItems, setShopItems] = useState<Product[]>([])
  const db = getFirestore(firebaseApp)
  const nav = useNavigate()
  useEffect(() => {
    const docRef = collection(db, "shopProducts")

    getDocs(docRef)
      .then((snap) => {
        const products = snap.docs.map((doc) => {
          return doc.data() as Product
        })

        setShopItems([...products])
      })
      .catch((error) => {
        console.log("Error getting document:", error)
      })
  }, [])

  return (
    <>
      <div className={styles.UpdateOrderDetails}>
        <div className={styles.UpdateOrderDetailsList}>
          <div
            className={styles.UpdateOrderStatusButton}
            onClick={() => {
              CreateNewShopProducts()
                .then((product) => {
                  nav(`./${product}`)
                })
                .catch((error) => {})
            }}
          >
            CREATE PRODUCT
          </div>
        </div>
      </div>

      <div className={styles.ProductListContainer}>
        {shopItems.map((val) => {
          return <PosterBlock product={val} key={val.uid} />
        })}
      </div>
    </>
  )
}

interface PosterProps {
  product: Product
}

export const PosterBlock: React.FC<PosterProps> = ({ product }): JSX.Element => {
  const vidRef = useRef<HTMLVideoElement>(null)
  const [imageURL, setImageURL] = useState(loadingImage)

  const nav = useNavigate()
  useEffect(() => {
    if (product.imageLDURL ?? "" == "") return
    const storage = getStorage()
    getDownloadURL(ref(storage, product.imageLDURL))
      .then((url) => {
        setImageURL(url)
      })
      .catch((error) => {
        console.error(error)
      })
  }, [product])

  return (
    <div
      className={styles.AdminPreviewProductContainer}
      onClick={() => {
        nav(`./${product.uid}`)
      }}
      onMouseEnter={() => {
        vidRef.current?.play()
      }}
      onMouseLeave={() => {
        vidRef.current?.pause()
      }}
      style={{ backgroundImage: "url(" + imageURL + ")" }}
    >
      <b>{product.title}</b>
    </div>
  )
}
