import styles from "./index.module.css"
import { useContext, useEffect, useRef, useState } from "react"
import { Product, ProductVariant } from "schema/dist/src/product"
import { AppContext, firebaseApp, setTabTitle } from "../../App"
import { collection, doc, getDocs, getFirestore, query, setDoc, where } from "firebase/firestore"
import { getDownloadURL, getStorage, ref } from "firebase/storage"
import { CartItem, ProductToCartItem } from "schema/dist/src/cart"
import { useLocation, useNavigate } from "react-router-dom"
import { BackURLKey, SizeToDimensions } from "../product"
import loadingImage from "./../../assets/loadingImage.png"
import { Category } from "schema/dist/src/category"
import { returnPageKey, ValidateUserToAnon } from "../../auth"
import { AddToCart } from "../../cart/cart"

const pageID = "tobennawes"
export const Main: React.FC<{}> = (): JSX.Element => {
  const [posterType, setPosterType] = useState<PosterType>("AR")

  const { setNavType } = useContext(AppContext)
  useEffect(() => {
    setNavType("sticky")
    return () => {
      setNavType("bar")
    }
  }, [])

  return (
    <div className={styles.root}>
      <div
        className={styles.SectionVideoContainer}
        onClick={() => {
          window.scrollTo({ top: window.innerHeight, behavior: "smooth" })
        }}
      >
        <div className={styles.SectionVideoContent}>
          <div className={styles.HeaderSection}>
            <div className={styles.HeaderSectionLogo} />
            <div className={styles.HeaderSectionButton}>Scroll</div>
          </div>
        </div>
      </div>
      <SelectPosterType posterType={posterType} onClick={setPosterType} />
      <PosterSection posterType={posterType} />
    </div>
  )
}
export default Main

interface PosterSectionProps {
  posterType: PosterType
}
export const PosterSection: React.FC<PosterSectionProps> = ({ posterType }): JSX.Element => {
  const [shopItems, setShopItems] = useState<Product[]>([])
  const db = getFirestore(firebaseApp)

  useEffect(() => {
    setTabTitle("Tobenna Wes Art")
    const docRef = collection(db, "shopProducts")
    const catRef = collection(db, "shopCategories")
    const catQuery = query(catRef, where("pageID", "==", pageID))

    getDocs(catQuery)
      .then((snap) => {
        const category = snap.docs.map((doc) => {
          return doc.data() as Category
        })

        return category[0]
      })
      .then((doc) => {
        let postersQuery = query(docRef, where("category", "==", doc.uid), where("isActive", "==", true))
        if (posterType === "regular") {
          postersQuery = query(postersQuery, where("typeId", "==", 1))
        } else if (posterType === "AR") {
          postersQuery = query(postersQuery, where("typeId", "==", 2))
        }

        return getDocs(postersQuery)
      })
      .then((snap) => {
        const products = snap.docs.map((doc) => {
          return doc.data() as Product
        })

        setShopItems([...products])
      })
      .catch((error) => {
        console.log("Error getting document:", error)
      })
  }, [db, posterType])

  return (
    <div className={styles.PosterSection}>
      {shopItems.map((val) => {
        return <Poster product={val} key={val.uid} />
      })}
    </div>
  )
}

interface PosterProps {
  product: Product
}

export const Poster: React.FC<PosterProps> = ({ product }): JSX.Element => {
  const { user } = useContext(AppContext)
  const loc = useLocation()
  const [addText, setAddText] = useState("Add To Cart")
  const [playing, setPlaying] = useState(false)
  const nav = useNavigate()

  if (product.productVariantList[0] === undefined) {
    return <></>
  }
  return (
    <div className={styles.PosterContainer}>
      <div
        className={styles.PosterContainerTop}
        onClick={(e) => {
          nav(`/shop/product/${product.uid}?${BackURLKey}=${loc.pathname}`)
          e.stopPropagation()
        }}
      >
        <div className={styles.PosterContainerPreview}>
          <PosterFrame
            imageURL={product.imageLDURL}
            videoURL={product.videoURL}
            ratio={SizeToDimensions(product.productVariantList[0].size) ?? 0.71}
            showVideo={product.typeId === 2}
            playing={playing}
            setPlaying={setPlaying}
          />
        </div>
        <div className={styles.PosterContainerInfo}>
          <div className={styles.PosterContainerInfoSize}>
            {product.title}
            <br />
            {product.productVariantList[0].size} <br />
            <span>Other Size</span>
          </div>
          <div className={styles.PosterContainerInfoSize}>$ {product.productVariantList[0].price}</div>
          <div
            className={styles.PosterContainerAddToCart}
            onClick={(e) => {
              e.stopPropagation()
              ValidateUserToAnon(user)
                .then((actualUser) => {
                  return AddToCart(actualUser.uid, product, product.productVariantList[0])
                })
                .then(() => {
                  setAddText("Added to Cart")
                })
                .catch((err) => {
                  console.error(err)
                })
            }}
          >
            {addText}
          </div>
        </div>
      </div>
      <div className={styles.PosterContainerBottom}>
        <PlayPosterContainer showPlayButton={product.typeId === 2} onPlay={setPlaying} play={playing} />
      </div>
    </div>
  )
}

interface PosterFrameProps {
  imageURL: string
  videoURL: string
  ratio: number
  showVideo: boolean
  setPlaying: (a: boolean) => void
  playing: boolean
}

export const PosterFrame: React.FC<PosterFrameProps> = ({
  imageURL,
  videoURL,
  ratio,
  showVideo,
  setPlaying,
  playing,
}): JSX.Element => {
  const vidRef = useRef<HTMLVideoElement>(null)
  const [actualImageURL, setActualImageURL] = useState(loadingImage)
  const [actualVideoURL, setActualVideoURL] = useState("")

  useEffect(() => {
    const storage = getStorage()
    getDownloadURL(ref(storage, imageURL))
      .then((url) => {
        setActualImageURL(url)
      })
      .catch((error) => {
        console.error(error)
      })
  }, [imageURL])

  useEffect(() => {
    const storage = getStorage()
    getDownloadURL(ref(storage, videoURL))
      .then((url) => {
        setActualVideoURL(url)
      })
      .catch((error) => {
        console.error(error)
      })
  }, [videoURL])

  useEffect(() => {
    if (vidRef.current === null) return
    if (playing) {
      vidRef.current.play()
    } else {
      vidRef.current.pause()
      vidRef.current.currentTime = 0
    }
  }, [playing])

  let posterFrameClass = styles.PosterFrameContainer

  if (ratio === 0.67) {
    posterFrameClass += " " + styles.PosterFrame067
  } else if (ratio === 0.75) {
    posterFrameClass += " " + styles.PosterFrame075
  } else if (ratio === 0.8) {
    posterFrameClass += " " + styles.PosterFrame08
  } else if (ratio === 1) {
    posterFrameClass += " " + styles.PosterFrame1
  }

  if (playing && showVideo) {
    posterFrameClass += " " + styles.PosterShowVide
  } else if (!showVideo) {
    posterFrameClass += " " + styles.PosterFrameStay
  }

  return (
    <div
      className={posterFrameClass}
      onMouseEnter={() => {
        setPlaying(true)
      }}
      onMouseLeave={() => {
        setPlaying(false)
      }}
    >
      <img src={actualImageURL} alt="frame_image"></img>
      <video src={actualVideoURL} controls loop autoPlay muted playsInline ref={vidRef} poster={loadingImage}></video>
      <div className={styles.PosterFrame}></div>
      <span style={{ backgroundImage: "url(" + loadingImage + ")" }}></span>
    </div>
  )
}

interface PlayPosterContainerProps {
  onPlay: (a: boolean) => void
  showPlayButton: boolean
  play: boolean
}

export const PlayPosterContainer: React.FC<PlayPosterContainerProps> = ({ showPlayButton, onPlay, play }): JSX.Element => {
  let className = styles.PlayPosterContainer

  if (play) {
    className += " " + styles.PausePosterContainer
  }

  if (!showPlayButton) {
    return <></>
  }

  return (
    <div
      className={className}
      onClick={() => {
        onPlay(!play)
      }}
    ></div>
  )
}

export type PosterType = "regular" | "AR"
interface SelectPosterTypeProps {
  onClick: (a: PosterType) => void
  posterType: PosterType
  selectorStyle?: string
}

export const SelectPosterType: React.FC<SelectPosterTypeProps> = ({ onClick, posterType, selectorStyle = "" }): JSX.Element => {
  const buttonList: PosterType[] = ["AR", "regular"]
  return (
    <div className={styles.SelectPosterTypeContainer + " " + selectorStyle}>
      {buttonList.map((val) => {
        let className = styles.SelectPosterType

        if (posterType === val) {
          className += " " + styles.SelectPosterTypeSelected
        }
        return (
          <div
            className={className}
            onClick={() => {
              onClick(val)
            }}
            key={val}
          >
            {val}
          </div>
        )
      })}
    </div>
  )
}
