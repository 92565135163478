import styles from "./index.module.css"
import { Routes, Route } from "react-router-dom"
import Overview from "./overview"
import NotFound from "../landing/404"
import React from "react"

/**
 * Account page handles all user information and order details
 * @returns
 */
export const Main: React.FC<{}> = (): JSX.Element => {
  return (
    <div className={styles.root}>
      <Routes>
        <Route path="/" element={<Overview />}>
          <Route path="/orders" element={<Overview />} />
          <Route path="/order/:orderID" element={<Overview />} />
          <Route path="/gallery" element={<Overview />} />
        </Route>
        <Route path="/*" element={<NotFound />}></Route>
      </Routes>
    </div>
  )
}
export default Main
