import React, { useContext, useEffect, useState } from "react"
import styles from "./cart.module.css"
import { getFirestore, doc, collection, getDoc } from "firebase/firestore"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { AppContext, firebaseApp } from "../App"
import { OrderDetails } from "schema/dist/src/order"
import { CartItem } from "schema/dist/src/cart"
import { getDownloadURL, getStorage, ref } from "firebase/storage"
import loadingImage from "./../assets/loadingImage.png"

export type RouteParams = {
  orderID: string
}

export const OrderBreakDown: React.FC<{}> = (): JSX.Element => {
  const nav = useNavigate()
  const loc = useLocation()
  const { orderID } = useParams<RouteParams>()
  const { user } = useContext(AppContext)
  const [orderDetailed, setOrderDetailed] = useState<OrderDetails>()

  useEffect(() => {
    if (user === null || orderID === undefined) {
      return
    }

    const db = getFirestore(firebaseApp)

    const orderDetailsRef = collection(doc(db, "userData", user.uid), "orderDetails")

    const orderRef = doc(orderDetailsRef, orderID)

    getDoc(orderRef)
      .then((response) => {
        const orderFinal = response.data() as OrderDetails
        setOrderDetailed(orderFinal)
      })

      .catch((err) => {
        console.error(err)
      })
  }, [user, loc.pathname, nav, orderID])

  return <OrderDetailedBreakDown orderDetailed={orderDetailed} />
}

export const OrderDetailedBreakDown: React.FC<{
  orderDetailed: OrderDetails | undefined
}> = ({ orderDetailed }): JSX.Element => {
  return (
    <div className={styles.OrderCompleteRoot}>
      <div className={styles.OrderCompleteDetails}>
        <div className={styles.OrderCompleteDetailsTitle}>
          <b>Order Details</b>
          <span> {orderDetailed?.orderStatus}</span>
        </div>

        <div>Order Number: {orderDetailed?.uid}</div>
        <div>Order Date: {orderDetailed?.timestamp.toDate().toDateString()}</div>

        {(orderDetailed?.shippingTrackingNo ?? "") !== "" ? <div>Tracking Number:{orderDetailed?.shippingTrackingNo}</div> : null}
      </div>

      {orderDetailed?.CartItems.map((order) => {
        return <CartItemBar cartData={order} key={order.uid} />
      })}

      <OrderCompleteSummary order={orderDetailed} />
    </div>
  )
}

interface CartItemBarProps {
  cartData: CartItem
}

export const CartItemBar: React.FC<CartItemBarProps> = ({ cartData }): JSX.Element => {
  const [imageURL, setImageURL] = useState(loadingImage)
  const nav = useNavigate()

  useEffect(() => {
    const storage = getStorage()
    getDownloadURL(ref(storage, cartData.productImageUrl))
      .then((url) => {
        setImageURL(url)
      })
      .catch((error) => {
        console.error("ERROR WITH API")

        console.error(error)
      })
  }, [cartData])

  return (
    <div
      className={styles.CartItemBar}
      onClick={() => {
        if (cartData.productType === "shopProduct") {
          nav("/shop/product/" + cartData.productUid)
        }
      }}
    >
      <div className={styles.CartItemImage} style={{ backgroundImage: "url(" + imageURL + ")" }} />
      <div className={styles.CartItemInfo}>
        <div>{cartData.title}</div>
        <div>{cartData.productVariant.size}</div>
        <div>Qty: {cartData.count}</div>

        <div>${cartData.productVariant.price}</div>
      </div>
    </div>
  )
}

interface OrderCompleteSummaryProps {
  order: OrderDetails | undefined
}

export const OrderCompleteSummary: React.FC<OrderCompleteSummaryProps> = ({ order }): JSX.Element => {
  return (
    <div className={styles.OrderCompleteSummary}>
      <h3>Summary</h3>
      <div className={styles.OrderCompleteSummarySection}>
        <OrderCompleteLine
          title={"Address"}
          children={
            <span>
              {order?.shippingAddress.name}
              <br />
              {order?.shippingAddress.line1}
              {order?.shippingAddress.line2}
              <br />
              {order?.shippingAddress.city} {order?.shippingAddress.state} {order?.shippingAddress.postalCode}
            </span>
          }
        />
      </div>
      <div className={styles.OrderCompleteSummarySection}>
        <OrderCompleteLine title={"Subtotal"} data={"$" + (order?.subTotal ?? 0) / 100} />
        <OrderCompleteLine title={"Shipping"} data={"$" + (order?.shippingAmount ?? 0) / 100} />
        <OrderCompleteLine title={"Est. Tax"} data={"$" + (order?.taxAmount ?? 0) / 100} />
        <OrderCompleteLine title={"Total"} data={"$" + (order?.total ?? 0) / 100} />
      </div>
      <div className={styles.OrderCompleteSummarySection}>
        <OrderCompleteLine title={"Need Help?"} />
        <OrderCompleteLine title={"Email"} data={"support@revivar.app"} />
      </div>
    </div>
  )
}
export default OrderBreakDown

interface OrderCompleteLineProps {
  title: string | undefined
  data?: string | undefined
}

export const OrderCompleteLine: React.FC<OrderCompleteLineProps> = ({ title, children, data }): JSX.Element => {
  return (
    <div className={styles.OrderCompleteLine}>
      <b>{title}</b>
      {data !== undefined ? <span>{data} </span> : children}
    </div>
  )
}
