import productStyles from "../product/index.module.css"
import { useEffect, useRef, useState } from "react"

import React from "react"
import { CreateProductVariant, Product, ProductVariant } from "schema/dist/src/product"
import { GetDefaultProductVariants, SelectProductVariantView } from "../product/create"
import { collection, deleteDoc, doc, getFirestore, setDoc } from "firebase/firestore"
import { firebaseApp } from "../../App"

export const Create: React.FC<{}> = (): JSX.Element => {
  return (
    <>
      <SetProductVariant type="defaults" />
      <SetProductVariant type="polaroids" />
    </>
  )
}

export default Create

export const SetProductVariant: React.FC<{ type: "polaroids" | "defaults" }> = ({ type }): JSX.Element => {
  const [variantList, setVariantList] = useState<ProductVariant[]>([])
  const [refreshView, setRefreshView] = useState(0)

  useEffect(() => {
    GetDefaultProductVariants(type).then((variantList) => {
      setVariantList(variantList)
    })
  }, [refreshView])

  return (
    <div className={productStyles.UpdateOrderDetails}>
      VARIANTS : {type.toUpperCase()}
      <div className={productStyles.SelectProductVariant}>
        {variantList.map((variant, i) => {
          return (
            <SelectProductVariantView
              key={i}
              variant={variant}
              onVariantUpdate={(newVar) => {
                console.log(newVar)
                UpdateDefaultVariant(newVar)
                  .then((res) => {
                    setRefreshView(refreshView + 1)
                  })
                  .catch((err) => {
                    console.log(err)
                  })
              }}
              onVariantDelete={() => {
                DeleteDefaultVariant(variant)
                  .then((res) => {
                    setRefreshView(refreshView + 1)
                  })
                  .catch((err) => {
                    console.log(err)
                  })
              }}
            />
          )
        })}
      </div>
      <div
        className={productStyles.UpdateOrderStatusButton}
        onClick={() => {
          CreateDefaultVariant().then((res) => {
            setRefreshView(refreshView + 1)
          })
        }}
      >
        ADD
      </div>
    </div>
  )
}

export function UpdateDefaultVariant(variant: ProductVariant) {
  const db = getFirestore(firebaseApp)
  const orderDetailsRef = doc(collection(doc(db, "appData", "posterVariants"), "defaults"), variant.uid)

  return setDoc(orderDetailsRef, variant)
}

export function DeleteDefaultVariant(variant: ProductVariant) {
  const db = getFirestore(firebaseApp)
  const orderDetailsRef = doc(collection(doc(db, "appData", "posterVariants"), "defaults"), variant.uid)

  return deleteDoc(orderDetailsRef)
}

export function CreateDefaultVariant() {
  const db = getFirestore(firebaseApp)
  const orderDetailsRef = doc(collection(doc(db, "appData", "posterVariants"), "defaults"))

  return setDoc(orderDetailsRef, CreateProductVariant(orderDetailsRef.id))
}
