import { getFunctions } from "firebase/functions"
import { Timestamp } from "firebase/firestore"
import { getFirestore, doc, collection, setDoc } from "firebase/firestore"
import { CreateDefaultPoster, Poster } from "schema/dist/src/poster"
import { firebaseApp, isUsingEmulator, LocalIP, FunctionsLocalPort, setAppConfig } from "../App"
import { CartItem } from "schema/dist/src/cart"
import { Product, ProductVariant } from "schema/dist/src/product"
import { OrderDetails } from "schema/dist/src/order"

export function GetServerUrl(endPoint: string): string {
  const functions = getFunctions(firebaseApp)

  let checkoutAPI = ""
  const emulatorURL = `http://${LocalIP}:${FunctionsLocalPort}/${setAppConfig.projectId}/${functions.region}`
  const functionsURL = `https://${functions.region}-${setAppConfig.projectId}.cloudfunctions.net`

  if (isUsingEmulator) {
    checkoutAPI = `${emulatorURL}${endPoint}`
  } else {
    checkoutAPI = `${functionsURL}${endPoint}`
  }

  return checkoutAPI
}

export function GetWebPageUrl() {
  return window.location.origin
}

/**
 * createRevivart is used to create a revivart object it does this by taking the
 * @param request which has the video and picture file
 *
 * @param defaultUserID
 * @param onUpdate
 * @returns
 */
// export function createRevivart(
//   request: PolaroidProps,
//   defaultUserID: string,
//   onUpdate: (a: number) => void
// ) {
//   const db = getFirestore(firebaseApp)
//   const storage = getStorage()

//   const posterRefParent = doc(db, "userData", defaultUserID)
//   const newPosterCol = collection(posterRefParent, "userPosters")
//   const newPosterRef = doc(newPosterCol)
//   const createdPoster = CreateDefaultPoster(
//     newPosterRef.id,
//     Timestamp.now(),
//     "Polaroid"
//   )

//   createdPoster.ownerId = defaultUserID
//   createdPoster.creatorId = defaultUserID

//   //TODO:REDUCE QUALITY
//   // const lowSizeImage = "LDARimage.jpg"
//   const highSizeImage = "HDARimage.jpg"
//   const videoName = "ARvideo.mp4"

//   // const LDImage = `userData/${createdPoster.creatorId}/${createdPoster.uid}/${lowSizeImage}`
//   const HDImage = `userData/${createdPoster.creatorId}/${createdPoster.uid}/${highSizeImage}`
//   const Video = `userData/${createdPoster.creatorId}/${createdPoster.uid}/${videoName}`

//   // let LDImageDestination = `gs://${setAppConfig.storageBucket}/${LDImage}`
//   let HDImageDestination = `gs://${setAppConfig.storageBucket}/${HDImage}`
//   let VideoDestination = `gs://${setAppConfig.storageBucket}/${Video}`

//   createdPoster.imageLDURL = HDImageDestination
//   createdPoster.imageHDURL = HDImageDestination
//   createdPoster.videoURL = VideoDestination

//   // const LDImageRequest = uploadBytesResumable(
//   //   ref(storage, HDImageDestination),
//   //   request.image!
//   // )

//   const HDImageRequest = uploadBytesResumable(
//     ref(storage, HDImageDestination),
//     request.image!
//   )

//   const VideoRequest = uploadBytesResumable(
//     ref(storage, VideoDestination),
//     request.video!
//   )

//   let VideoRequestProgress = 0
//   VideoRequest.on("state_changed", (snapshot) => {
//     VideoRequestProgress =
//       ((snapshot.bytesTransferred / snapshot.totalBytes) * 100) / 2
//     onUpdate(getTotalPercent())
//   })

//   let HDImageRequestProgress = 0
//   HDImageRequest.on("state_changed", (snapshot) => {
//     HDImageRequestProgress =
//       ((snapshot.bytesTransferred / snapshot.totalBytes) * 100) / 3
//     onUpdate(getTotalPercent())
//   })

//   // let LDImageRequestProgress = 0
//   // LDImageRequest.on("state_changed", (snapshot) => {
//   //   LDImageRequestProgress =
//   //     ((snapshot.bytesTransferred / snapshot.totalBytes) * 100) / 3
//   //   onUpdate(getTotalPercent())
//   // })

//   function getTotalPercent(): number {
//     return VideoRequestProgress + HDImageRequestProgress
//   }

//   return new Promise<Poster>((resolve, reject) => {
//     Promise.all([HDImageRequest, VideoRequest])
//       .then((response) => {
//         console.log(response.length, "uploaded")
//         createdPoster.timestamp = Timestamp.now()
//         return setDoc(newPosterRef, createdPoster)
//       })
//       .then((response) => {
//         resolve(createdPoster)
//         console.log(createdPoster, "set")
//       })
//       .catch((error) => {
//         console.log(error)
//         reject(error)
//       })
//   })
// }

/**
 * posterToCartItem uses a poster data to create a cart item then will be used in a order
 * @param data - the poster data to be used in creating cart data
 * @param customerUId
 * @param variant
 * @param count
 * @returns
 */
export function posterToCartItem(data: Poster, uid: string, variant: ProductVariant, count: number): CartItem {
  const newCard: CartItem = {
    uid: uid,
    productUid: data.uid,
    productImageUrl: data.imageHDURL,
    productImageUrlHD: data.imageHDURL,
    orderImageUrlLD: data.imageHDURL,
    orderImageUrlHD: data.imageHDURL,
    title: data.title,
    productType: "userPoster",
    count: count,
    productVariant: variant,
  }
  return newCard
}

export function cartItemToOrder(uid: string, data: CartItem[], customerUId: string): OrderDetails {
  const neworder: OrderDetails = {
    orderSession: "",
    shippingAddress: {
      line1: "",
      line2: "",
      city: "",
      country: "",
      name: "",
      phone: "",
      email: "",
      state: "",
      postalCode: "",
    },
    shippingTrackingNo: "",
    shippingProvider: "",
    timestamp: Timestamp.now(),
    paymentID: "",
    paymentMethod: "",
    subTotal: 0,
    shippingAmount: 0,
    taxAmount: 0,
    total: 0,
    CartItems: data,
    uid: uid,
    paymentStatus: "waiting",
    orderStatus: "initiated",
    idempotency: "",
    customerId: customerUId,
    paymentMethodId: "",
    customerStripId: "",
    email: "",
  }

  return neworder
}

export function SetOrderSession(data: CartItem[], customerUId: string) {
  const db = getFirestore(firebaseApp)
  const posterRefParent = doc(db, "userData", customerUId)
  const newOrderCol = collection(posterRefParent, "orderSession")
  const newPosterRef = doc(newOrderCol)

  const orderDetails = cartItemToOrder(newPosterRef.id, data, customerUId)

  return setDoc(newPosterRef, orderDetails).then((response) => {
    return orderDetails.uid
  })
}
