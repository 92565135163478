import { FirebaseOptions } from "firebase/app"

export const firebaseConfigStaging: FirebaseOptions = {
  apiKey: "AIzaSyAGGTJ0R3PHPfCUqG1Vfx5YxR0KEbYAkOg",
  authDomain: "revivar-staging.firebaseapp.com",
  projectId: "revivar-staging",
  storageBucket: "revivar-staging.appspot.com",
  messagingSenderId: "709302113365",
  appId: "1:709302113365:web:dd0b048790ebc1239cb0dc",
  measurementId: "G-LY8E6X5JBX",
}
export const firebaseConfigDev: FirebaseOptions = {
  apiKey: "AIzaSyCzy428FVrucRcr_gmOBdFL8F1C_sLVJ_c",
  authDomain: "revivar-dev.firebaseapp.com",
  projectId: "revivar-dev",
  storageBucket: "revivar-dev.appspot.com",
  messagingSenderId: "535381203937",
  appId: "1:535381203937:web:98e4fc47437d49a58250a0",
  measurementId: "G-SHPDF4P2DD",
}
export const firebaseConfigProd: FirebaseOptions = {
  apiKey: "AIzaSyD_Bq7R1DzCJumR-rvtUsnbnagRY2Dbm3Q",
  authDomain: "augmented-art.firebaseapp.com",
  databaseURL: "https://augmented-art.firebaseio.com",
  projectId: "augmented-art",
  storageBucket: "augmented-art.appspot.com",
  messagingSenderId: "241155750060",
  appId: "1:241155750060:web:49a3c1d24ae5967dff0709",
  measurementId: "G-BQ8BEN701X",
}
