import styles from "./index.module.css"
import { Suspense, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { firebaseApp, previewAppWebsite, setTabTitle } from "../App"
import { doc, getDoc, getFirestore } from "firebase/firestore"
import { Sticker } from "schema/dist/src/sticker"
import { stickerParam } from "../landing/activate"
import loadingImage from "./../assets/loadingImage.png"

import { Poster } from "schema/dist/src/poster"
import { getDownloadURL, getStorage, ref } from "firebase/storage"

type RouteParams = {
  stickerID: string
}

export const Main: React.FC<{}> = (): JSX.Element => {
  const { stickerID } = useParams<RouteParams>()
  const [stickerData, setStickerData] = useState<Sticker>()
  const [posterData, setPosterData] = useState<Poster>()

  const nav = useNavigate()
  useEffect(() => {
    setTabTitle("Sticker")
    if (stickerID === undefined) {
      return
    }

    const db = getFirestore(firebaseApp)
    const docRef = doc(db, "userStickers", stickerID ?? "")
    getDoc(docRef)
      .then((docSnap) => {
        if (docSnap.exists()) {
          const sticker = docSnap.data() as Sticker
          setStickerData(sticker)

          return sticker
        } else {
          console.log("No such document!")
        }
      })
      .then((sticker) => {
        if (sticker == undefined) {
          return
        }
        const docRef = doc(db, "userPosters", sticker.posterUid)
        //GET POSTER DOC
        return getDoc(docRef)
      })
      .then((poster) => {
        if (poster == undefined) {
          return
        }

        if (poster.exists()) {
          setPosterData(poster.data() as Poster)
        }
      })
  }, [stickerID])

  return (
    <div className={styles.stickerRoot}>
      {stickerData != undefined ? (
        <>
          {(stickerData?.posterUid ?? "") === "" ? (
            <>
              <div className={styles.MainLogo}></div>

              <div
                className={styles.StickerButton}
                onClick={() => {
                  nav(`/activate?${stickerParam}=${stickerID}`)
                }}
              >
                Activate
              </div>
            </>
          ) : (
            <>
              {posterData !== undefined ? (
                <GalleryAsset poster={posterData!}>
                  <div className={styles.MainLogo}></div>
                </GalleryAsset>
              ) : null}

              <div
                className={styles.StickerButton}
                onClick={() => {
                  window.open(previewAppWebsite + "/sticker/" + stickerID)
                }}
              >
                Preview in Browser
              </div>
              <div
                className={styles.StickerButton}
                onClick={() => {
                  window.open(" https://apps.apple.com/us/app/revivar/id1559129541")
                }}
              >
                Open in App
              </div>
            </>
          )}
        </>
      ) : null}
    </div>
  )
}

export default Main

export const GalleryAsset: React.FC<{ poster: Poster }> = ({ poster, children }): JSX.Element => {
  const [actualURL, setActualURL] = useState(loadingImage)
  const nav = useNavigate()

  useEffect(() => {
    const storage = getStorage()
    if (poster.imageLDURL.includes("gs://")) {
      const imageStorageRef = ref(storage, poster.imageLDURL)
      getDownloadURL(imageStorageRef).then((downloadURL) => {
        setActualURL(downloadURL)
      })
    } else {
      setActualURL(poster.imageLDURL ?? "")
    }
  }, [poster.imageLDURL])

  return (
    <div
      className={styles.GalleryAsset}
      style={{ backgroundImage: "url(" + actualURL + ")" }}
      onClick={() => {
        nav("/asset/" + poster.uid)
      }}
    >
      {children}
    </div>
  )
}
