import styles from "./index.module.css"
import { useContext, useEffect, useState } from "react"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { doc, getDoc, getFirestore } from "firebase/firestore"
import { AppContext, firebaseApp, setTabTitle } from "../../App"
import { getDownloadURL, getStorage, ref } from "firebase/storage"
import { Product } from "schema/dist/src/product"
import loadingImage from "./../../assets/loadingImage.png"
import { ImageGallery, ImageGalleryProps, PosterViewState } from "./frame"
import { ValidateUserToAnon } from "../../auth"
import { User } from "firebase/auth"
import { AddToCart } from "../../cart/cart"

type RouteParams = {
  productID: string
}

export function SizeToDimensions(size: string): number | undefined {
  const stringRation = size.replaceAll(" ", "").replaceAll('"', "").split("x")

  if (stringRation.length === 2) {
    const top = parseFloat(stringRation[0])
    const bottom = parseFloat(stringRation[1])

    if (isNaN(top) || isNaN(bottom)) return
    return Number((top / bottom).toFixed(2))
  }

  return
}
export const BackURLKey = "returnURL"

export const Main: React.FC<{}> = (): JSX.Element => {
  const { productID } = useParams<RouteParams>()
  const [productData, setProductData] = useState<Product>()
  const search = useLocation().search
  const nav = useNavigate()
  const [imageURL, setImageURL] = useState(loadingImage)
  const [videoURL, setVideoURL] = useState("")
  const [playVideo, setPlayVideo] = useState(false)
  const [viewState, setViewState] = useState<PosterViewState>("normal")

  const [ratio, setRatio] = useState(0.8)
  const [selectedVariant, setSelectedVariant] = useState(0)
  const [selectedSlide, setSelectedSlide] = useState(0)

  const backURL = new URLSearchParams(search).get(BackURLKey) ?? ""
  const { user } = useContext(AppContext)

  useEffect(() => {
    const db = getFirestore(firebaseApp)

    if (productID === undefined) return

    const docRef = doc(db, "shopProducts", productID ?? "")
    getDoc(docRef).then((docSnap) => {
      //TODO :: USE PRODUCT NAME

      if (docSnap.exists()) {
        const product = docSnap.data() as Product
        setTabTitle(product.title)
        setProductData(product)
      } else {
        console.log("No such document!")
      }
    })
  }, [productID])

  useEffect(() => {
    if (productData === undefined) return

    const storage = getStorage()
    getDownloadURL(ref(storage, productData.imageLDURL))
      .then((url) => {
        setImageURL(url)
      })
      .catch((error) => {
        console.error(error)
      })

    if (productData.typeId === 2) {
      getDownloadURL(ref(storage, productData.videoURL))
        .then((url) => {
          setVideoURL(url)
        })
        .catch((error) => {
          console.error(error)
        })
    }

    const looseRatio = productData.productVariantList[selectedVariant].size
    const ratio = SizeToDimensions(looseRatio)

    if (ratio !== undefined) {
      setRatio(ratio)
    }
  }, [productData, selectedVariant])

  return (
    <div className={styles.root}>
      <div className={styles.BackButtonContainer}>
        <div className={styles.BackButtonIcon} onClick={() => {}}></div>
        <div
          className={styles.BackButton}
          onClick={() => {
            if (backURL === "") {
              nav("/shop")
            } else {
              nav(backURL)
            }
          }}
        >
          Back
        </div>
      </div>
      <div className={styles.MainContentContainer}>
        <div className={styles.PosterContentContainer}>
          <PosterContainer
            imageURL={imageURL}
            videoURL={videoURL}
            ratio={ratio}
            play={playVideo}
            selectedSlide={selectedSlide}
            productData={productData}
            rotate
            viewState={viewState}
            setPlay={setPlayVideo}
          />
          <PlayPosterContainer
            product={productData}
            user={user}
            onPlay={setPlayVideo}
            onViewState={setViewState}
            viewState={viewState}
            play={playVideo}
          />

          <PosterRelatedContainer product={productData} user={user} showSelection={setSelectedSlide} />
        </div>
        <ProductInfo product={productData} user={user} setSelectedVariant={setSelectedVariant} />
      </div>
    </div>
  )
}
export default Main

interface PosterContainerProps extends ImageGalleryProps {
  productData: Product | undefined
  selectedSlide: number
}

export const PosterContainer: React.FC<PosterContainerProps> = ({
  imageURL,
  videoURL,
  ratio,
  play,
  productData,
  selectedSlide,
  viewState,
  rotate,
  setPlay,
}): JSX.Element => {
  const [mainImageURl, setMainImageURl] = useState(loadingImage)

  useEffect(() => {
    if (productData === undefined) return

    const storage = getStorage()

    let imageRef = productData.imageLDURL

    if (selectedSlide > 0) {
      imageRef = productData.productImagesURL[selectedSlide - 1]
    }

    getDownloadURL(ref(storage, imageRef))
      .then((url) => {
        setMainImageURl(url)
      })
      .catch((error) => {
        console.error(error)
      })
  }, [productData, selectedSlide])

  if (selectedSlide !== 0 || productData?.typeId === 3) {
    return (
      <div className={styles.PosterContainer}>
        <img src={mainImageURl} alt={"container"} />
      </div>
    )
  }

  return (
    <div className={styles.PosterContainer}>
      <ImageGallery
        imageURL={imageURL}
        videoURL={videoURL}
        ratio={ratio}
        play={play}
        rotate={rotate}
        viewState={viewState}
        setPlay={setPlay}
      />
    </div>
  )
}

interface PosterRelatedContainerProps {
  product: Product | undefined
  user: User | null
  showSelection?: (a: number) => void
  setSelectedVariant?: (a: number) => void
}
export const PosterRelatedContainer: React.FC<PosterRelatedContainerProps> = ({ product, showSelection }): JSX.Element => {
  const [imageURLs, setImageURLs] = useState<string[]>([])
  const [mainImageURl, setMainImageURl] = useState(loadingImage)

  useEffect(() => {
    if (product === undefined) return

    const storage = getStorage()

    const allURLRequest = product.productImagesURL.map((url) => {
      return getDownloadURL(ref(storage, url))
    })
    Promise.all(allURLRequest).then((urls) => {
      setImageURLs(urls)
    })

    getDownloadURL(ref(storage, product.imageLDURL))
      .then((url) => {
        setMainImageURl(url)
      })
      .catch((error) => {
        console.error(error)
      })
  }, [product])

  return (
    <div className={styles.PosterRelatedContainer}>
      <img
        src={mainImageURl}
        onClick={() => {
          showSelection!(0)
        }}
        alt="related"
      ></img>
      {imageURLs.map((imageURL, i) => {
        return (
          <img
            key={imageURL}
            src={imageURL}
            onClick={() => {
              showSelection!(i + 1)
            }}
            alt="related"
          ></img>
        )
      })}
    </div>
  )
}

export const ProductInfo: React.FC<PosterRelatedContainerProps> = ({ product, user, setSelectedVariant }): JSX.Element => {
  const [shareText, setShareText] = useState("Share")
  return (
    <div className={styles.PosterInfoContainer}>
      <div className={styles.PosterInfoName}>
        {product?.title ?? "Name"}
        <div
          className={styles.PosterInfoShare}
          onClick={() => {
            navigator.clipboard.writeText(window.location.toString())
            setShareText("Copied to clipboard")
          }}
        ></div>
        <span>{shareText}</span>
      </div>

      {product?.productDescription !== undefined && product?.productDescription !== "" ? (
        <div className={styles.PosterInfoDescription}>
          <b>Description</b>
          {product?.productDescription ?? "Description"}
        </div>
      ) : null}

      <AttributesList product={product} user={user} />
      <ProductActionsContainer product={product} user={user} setSelectedVariant={setSelectedVariant} />
      <IncludedList product={product} user={user} />
    </div>
  )
}
export const ProductActionsContainer: React.FC<PosterRelatedContainerProps> = ({
  product,
  user,
  setSelectedVariant,
}): JSX.Element => {
  const [count, setCount] = useState(1)
  const [selectVariant, setSelectVariant] = useState(0)
  const [addText, setAddText] = useState("Add to Cart")
  const nav = useNavigate()
  const countList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]

  if (product === undefined) {
    return <></>
  }

  return (
    <div className={styles.PosterInfoActionsContainer}>
      <div className={styles.PosterInfoActionsPrice}>
        <div>
          Price: <b>${product?.productVariantList[selectVariant].price}</b>
        </div>

        <div className={styles.PosterInfoActionsQuantity}>
          Qty:
          <select
            onChange={(e) => {
              let count = parseInt(e.currentTarget.value, 10)
              //MAX ORDER COUNT IS 100
              if (isNaN(count) || count >= 100 || count === 0) {
                count = 1
              }
              setCount(count)
            }}
            defaultValue={count}
          >
            {countList.map((number) => {
              return (
                <option key={number} value={number}>
                  {number}
                </option>
              )
            })}
          </select>
        </div>
      </div>
      <div className={styles.PosterInfoActionsSizePreview}>
        Selected Size: <b>{product?.productVariantList[selectVariant].size}</b>
      </div>
      <div className={styles.PosterInfoActionsSizeList}>
        {product?.productVariantList.map((variant, index) => {
          let className = styles.PosterInfoActionsSizeButton

          if (index === selectVariant) {
            className += " " + styles.PosterInfoActionsSizeSelected
          }
          return (
            <div
              className={className}
              key={variant.size}
              onClick={() => {
                setSelectVariant(index)
                setSelectedVariant!(index)
              }}
            >
              {variant.size}
            </div>
          )
        })}
      </div>
      <div
        className={styles.PosterInfoActionsAddToCart}
        onClick={() => {
          if ((product.studioID ?? "") !== "") {
            nav("/studio/" + product.studioID)
            return
          }

          ValidateUserToAnon(user)
            .then((actualUser) => {
              return AddToCart(actualUser.uid, product, product?.productVariantList[selectVariant], count)
            })
            .then(() => {
              setAddText("Added to Cart")
            })
            .catch(() => {})
        }}
      >
        {addText}
      </div>
    </div>
  )
}

export const AttributesList: React.FC<PosterRelatedContainerProps> = ({ product }): JSX.Element => {
  if (product?.typeId === 3 && product?.nftLink === "") {
    return <></>
  }

  return (
    <div className={styles.PosterInfoAttributes}>
      <b>Attributes </b>
      {product?.typeId === 2 ? (
        <div className={styles.PosterInfoAttributeContainer}>
          <div className={styles.LiveEnabledIcon}></div>
          <div className={styles.PosterAttributeInfo}>
            <b>AR ENABLED</b>
            Anyone can see the video using our app or website
          </div>
          <div className={styles.RevivarBlackIcon}></div>
        </div>
      ) : null}
      {product?.typeId === 1 ? (
        <div className={styles.PosterInfoAttributeContainer}>
          <div className={styles.StillEnabledIcon}></div>
          <div className={styles.PosterAttributeInfo}>
            <b>STILL POSTER </b>
            This poster has no AR video attached to it
          </div>
        </div>
      ) : null}
      {product?.nftLink !== "" ? (
        <div
          className={styles.PosterInfoAttributeContainer}
          onClick={() => {
            window.open(product?.nftLink)
          }}
        >
          <div className={styles.NFTEnabledIcon}></div>
          <div className={styles.PosterAttributeInfo}>
            <b>NFT LINKED</b>
            This poster has an NFT attached to it
          </div>
          <div className={styles.OpenLinkIcon}></div>
        </div>
      ) : null}
    </div>
  )
}

export const IncludedList: React.FC<PosterRelatedContainerProps> = ({ product }): JSX.Element => {
  return (
    <div className={styles.PosterInfoAttributes}>
      <b>Whats included </b>
      <div className={styles.PosterInfoActionsIncludedContainer}>
        <IncludedListItem
          imageClass={styles.FilePictureIcon}
          title={"Print of the picture you ordered"}
          isVisible={product?.typeId !== 3}
        />
        <IncludedListItem imageClass={styles.TextureIcon} title={"Premium Matte Texture"} isVisible={product?.typeId !== 3} />
        <IncludedListItem imageClass={styles.NotNopeIcon} title={"Frames are NOT include"} isVisible={product?.typeId !== 3} />
        <IncludedListItem imageClass={styles.QRCodeIcon} title={"QR code / NFC sticker"} isVisible={product?.typeId === 2} />
      </div>
    </div>
  )
}

export const IncludedListItem: React.FC<{
  imageClass: string
  title: string
  isVisible: boolean
}> = ({ imageClass, title, isVisible }): JSX.Element => {
  if (!isVisible) {
    return <></>
  }
  return (
    <div className={styles.PosterInfoActionsIncluded}>
      <div className={styles.LiveEnabledIcon + " " + imageClass}></div>
      <div className={styles.PosterAttributeInfo}>{title}</div>
    </div>
  )
}

interface PlayPosterContainerProps extends PosterRelatedContainerProps {
  onViewState: (a: PosterViewState) => void
  viewState: PosterViewState
  play: boolean
  onPlay: (a: boolean) => void
}

export const PlayPosterContainer: React.FC<PlayPosterContainerProps> = ({
  product,
  onPlay,
  onViewState,
  viewState,
  play,
}): JSX.Element => {
  if (product?.typeId !== 2) {
    return <></>
  }

  let className = styles.PlayPosterContainer

  if (play) {
    className += " " + styles.PausePosterContainer
  }

  let ExpandButtonClass = styles.PlayPosterContainer
  ExpandButtonClass += " " + styles.ExpandButton

  if (viewState === "expand") {
    ExpandButtonClass += " " + styles.CollapseButton
  } else if (viewState === "compare") {
    ExpandButtonClass += " " + styles.CompareButton
  }

  return (
    <div className={styles.PlayButtonStack}>
      <div
        className={className}
        onClick={() => {
          onPlay(!play)
        }}
      ></div>
      <div
        className={ExpandButtonClass}
        onClick={() => {
          if (viewState === "expand") {
            onViewState("compare")
          } else if (viewState === "compare") {
            onViewState("normal")
          } else {
            onViewState("expand")
          }
        }}
      />
    </div>
  )
}
