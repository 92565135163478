import React, { useEffect } from "react"
import styles from "./create.module.css"
import { getDocs, query, setDoc, where } from "firebase/firestore"
import { getFirestore, doc, collection } from "firebase/firestore"
import { firebaseApp } from "../../App"
import { Product, ProductVariant } from "schema/dist/src/product"
import { StudioSession, StudioSessionAssets, SessionType } from "schema/dist/src/studio"
import { CloseStudioSession } from "./schema"
import { IncludedListItem } from "../../shop/product"
import { AddToCart } from "../../cart/cart"
import { CreationControllerStart } from "../polaroid"
interface VariantContainerProps {
  product: Product | undefined
  setProduct: (a: Product | undefined) => void
  selectedVariant: ProductVariant | undefined
  setSelectedVariant: (a: ProductVariant | undefined) => void
  studioID: string
  studioSession: StudioSession | undefined
}
/**
 * PolaroidPreview is used to preview a polaroid in order section
 * @param param0
 *
 * @returns
 */
export const VariantContainer: React.FC<VariantContainerProps> = ({
  product,
  setProduct,
  selectedVariant,
  setSelectedVariant,
  studioID,
  studioSession,
}): JSX.Element => {
  useEffect(() => {
    const db = getFirestore(firebaseApp)
    const posterVariantsRef = collection(db, "shopProducts")
    const qu = query(posterVariantsRef, where("studioID", "==", studioID))
    getDocs(qu)
      .then((responses) => {
        const products: Product[] = []
        responses.docs.forEach((doc) => {
          const variant = doc.data() as Product
          products.push(variant)
        })
        const selectedProd = products[0]
        selectedProd.productVariantList = selectedProd?.productVariantList?.sort((a, b) => {
          return a.price < b.price ? -1 : 1
        })

        setProduct(selectedProd)

        let found = false
        selectedProd.productVariantList.forEach((variant, i) => {
          if (studioSession?.productVariant.count === variant.count) {
            setSelectedVariant(selectedProd.productVariantList[i])
            found = true
          }
        })

        if (!found) {
          setSelectedVariant(selectedProd.productVariantList[0])
        }
      })
      .catch((err) => {
        console.error(err)
      })
  }, [studioID, studioSession?.productVariant?.count])

  if (studioSession === undefined) return <></>

  return (
    <div className={styles.VariantContainer}>
      Which works for you:
      <div className={styles.VariantContainerList}>
        {product?.productVariantList?.map((val) => {
          const isSelected = val === selectedVariant

          let containerClass = styles.VariantButton

          if (isSelected) {
            containerClass += " " + styles.VariantButtonSelected
          }

          return (
            <div
              key={val.size}
              className={containerClass}
              onClick={() => {
                if (!studioSession?.editable) return
                setSelectedVariant(val)
              }}
            >
              <div> {val.size} </div>
              <div> ${val.price} </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export const IntroVideo: React.FC<{ videoURL: string; backdrop?: string }> = ({
  videoURL,
  children,
  backdrop = "",
}): JSX.Element => {
  return (
    <div className={styles.IntroVideoContainer} style={{ backgroundImage: "url(" + backdrop + ")" }}>
      <div className={styles.IntroVideoContainerMain}>{children}</div>
      <video loop autoPlay muted playsInline src={videoURL}></video>
    </div>
  )
}

export function CreateStudioSession(selectedVariant: ProductVariant, userID: string, sessionType: SessionType) {
  const db = getFirestore(firebaseApp)
  const sessionCollRef = collection(db, "studioSessions")
  let sessionRef = doc(sessionCollRef)
  const sessionData: StudioSessionAssets[] = []

  for (let index = 0; index < (selectedVariant.count ?? 1); index++) {
    const posterRef = doc(collection(db, "userPosters"))
    sessionData.push({
      videoURL: "",
      imageURL: "",
      posterUID: posterRef.id,
    })
  }

  const newSession: StudioSession = {
    uid: sessionRef.id,
    sessionType: sessionType,
    sessionData: sessionData,
    customerID: userID,
    productVariant: selectedVariant,
    editable: true,
  }

  return setDoc(sessionRef, newSession).then(() => {
    return newSession
  })
}

export function AddSessionToCart(userID: string, session: StudioSession, studioID: string, sessionID: string, product: Product) {
  session.productVariant.studioID = studioID
  session.productVariant.studioSessionID = sessionID
  return CloseStudioSession(session).then(() => {
    return AddToCart(userID, product, session.productVariant)
  })
}

export const IncludedList: React.FC<{}> = ({}): JSX.Element => {
  return (
    <div className={styles.PosterInfoAttributes}>
      <b>Whats included </b>
      <div className={styles.PosterInfoActionsIncludedContainer}>
        <IncludedListItem imageClass={styles.FilePictureIcon} title={"Print of the picture you ordered"} isVisible={true} />
        <IncludedListItem imageClass={styles.TextureIcon} title={"Premium Matte Texture"} isVisible={true} />
        <IncludedListItem imageClass={styles.NotNopeIcon} title={"Frames are NOT include"} isVisible={true} />
        <IncludedListItem imageClass={styles.QRCodeIcon} title={"Revivar NFC sticker"} isVisible={true} />
      </div>
    </div>
  )
}

export const FrequentList: React.FC<{}> = ({}): JSX.Element => {
  return (
    <div className={styles.PosterInfoAttributes}>
      <b>Frequently Asked Question</b>
      <div className={styles.FrequentListContainer}>
        <b>Is this an NFT?</b>
        <span>No this is not an NFT but can be used to show host your NFT image and picture</span>
        {/* <b>How does it work?</b>
        <span>Its not easy</span> */}
      </div>
    </div>
  )
}

export const CreationSteps: React.FC<{ steps: number }> = ({ steps }): JSX.Element => {
  return (
    <div className={styles.CreationSteps}>
      <b> Revivar Creation Steps</b>
      <span className={steps === 0 ? styles.CreationStepsBold : ""}>
        Step 1: Upload the video that will be visible through AR
      </span>
      <span className={steps === 1 ? styles.CreationStepsBold : ""}>
        Step 2: Move the slider select a frame and or upload the image that will be printed
      </span>
      <span className={steps === 2 ? styles.CreationStepsBold : ""}>
        Step 3: Add your work to cart and Revivar does the rest for you
      </span>
    </div>
  )
}

export function ValidateAssetSize(a: File): { valid: boolean; reason: string } {
  const size = a.size / (1024 * 1024)
  const maxSize = 30
  const isOverSized = size > maxSize

  if (isOverSized) {
    return { valid: false, reason: `Error: File size is ${size.toFixed(1)}MB, Max is ${maxSize}MB` }
  }

  return { valid: true, reason: "" }
}

export function ValidateImageSize(a: File | Blob): Promise<{ valid: boolean; reason: string }> {
  const size = a.size / (1024 * 1024)
  const maxSize = 50
  const isOverSized = size > maxSize
  var image = new Image()
  var objectUrl = URL.createObjectURL(a)

  return new Promise<{ valid: boolean; reason: string }>((resolve, reject) => {
    if (isOverSized) {
      return resolve({ valid: false, reason: `File size is ${size}MB, Max is ${maxSize}MB` })
    }

    image.onload = function () {
      console.log(image.width, image.height)

      const diagonal = Math.sqrt(image.width * image.width + image.height * image.height)

      console.log(diagonal)
      URL.revokeObjectURL(objectUrl)
    }
    image.src = objectUrl
  })
}

interface IntroBodyProps {
  session: StudioSession | undefined
  onClick: () => void
  title: string
  subTitle: string
}

export const IntroBody: React.FC<IntroBodyProps> = ({ session, onClick, title, subTitle }): JSX.Element => {
  return (
    <div className={styles.IntroBodyProps}>
      <b>{title} </b>
      <span> {subTitle}</span>
      <CreationControllerStart onClick={onClick} isHidden={session !== undefined} text={"Start Designing"} />
    </div>
  )
}
