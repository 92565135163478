import { GetServerUrl, GetWebPageUrl } from "./createRevivart"
import axios from "axios"

export const isFailedKey = "failed"

export const StartCheckOut = (
  orderID: string | undefined,
  userID: string,
  userToken: string
) => {
  const checkoutEndPoint = "/api/api/checkout"
  const updateOrderPoint = "/api/api/checkout/update"
  let checkoutAPI = GetServerUrl(checkoutEndPoint)
  let updateOrderDetailsApi = GetServerUrl(updateOrderPoint)

  // revivar page of cancelled polaroids

  const cancelURL = new URL(`${GetWebPageUrl()}/cart`)
  cancelURL.searchParams.append(isFailedKey, "true")
  cancelURL.searchParams.append("orderID", orderID ?? "")

  // revivar order page / order complete and orderDetails ID
  let redirectURL = `${GetWebPageUrl()}/account/order/${orderID}`

  const returnURL = new URL(updateOrderDetailsApi)
  returnURL.searchParams.append("orderID", orderID ?? "")
  returnURL.searchParams.append("redirectURL", redirectURL)
  returnURL.searchParams.append("customerID", userID)
  returnURL.searchParams.append("access_token", userToken)

  const requestURL = new URL(checkoutAPI)
  requestURL.searchParams.append("orderID", orderID ?? "")
  requestURL.searchParams.append("returnURL", returnURL.toString())
  requestURL.searchParams.append("cancelURL", cancelURL.toString())
  requestURL.searchParams.append("customerID", userID)

  return axios
    .get(requestURL.toString(), {
      headers: { Authorization: "Bearer " + userToken },
    })
    .then((response) => {
      window.open(response.data.redirect, "_self")
    })
}
