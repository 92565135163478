import styles from "./styles/home.module.css"
import { useEffect, useState } from "react"
import { getFirestore, collection, query, getDocs } from "firebase/firestore"
import { firebaseApp } from "../../App"
import { Poster } from "schema/dist/src/poster"
import * as THREE from "three"
import { Suspense } from "react"
import React, { useRef } from "react"
import { Canvas, useLoader, MeshProps } from "@react-three/fiber"
import { OrbitControls } from "@react-three/drei"
import { TextureLoader } from "three/src/loaders/TextureLoader"
import NightEarthTexture from "../../assets/8k_earth_nightmap.jpg"

import { vertexShader, fragShader, atmosphereFragShader, atmosphereVertexShader } from "./shaders"
interface VisualComponentProps {
  onRevivartClick: (poster: Poster | undefined) => void
}

const Globe: React.FC<VisualComponentProps> = ({ onRevivartClick }): JSX.Element => {
  const colorMap = useLoader(TextureLoader, NightEarthTexture)

  const uniforms = {
    globeTexture: {
      value: colorMap,
    },
  }

  return (
    <group>
      <mesh position={[0, 0, 0]} rotation={[0, -Math.PI / 2, 0]}>
        <sphereGeometry args={[1, 1000]} />
        <shaderMaterial fragmentShader={fragShader} vertexShader={vertexShader} uniforms={uniforms} />
      </mesh>

      <mesh position={[0, 0, 0]} rotation={[0, 0, 0]}>
        <sphereGeometry args={[1, 100]} />
        <shaderMaterial
          fragmentShader={atmosphereFragShader}
          vertexShader={atmosphereVertexShader}
          blending={THREE.AdditiveBlending}
          side={THREE.BackSide}
        />
      </mesh>
      <AllPosters onRevivartClick={onRevivartClick} />
    </group>
  )
}

interface LocationProps {
  lat: number
  lon: number
  revivart?: Poster
  onClick: (poster: Poster | undefined) => void
}

const Location: React.FC<LocationProps> = ({ lon, lat, revivart, onClick }): JSX.Element => {
  const latitude = (lat / 180) * Math.PI
  const longitude = (lon / 180) * Math.PI
  const radius = 1
  const [hovered, setHovered] = useState(false)

  const x = radius * Math.cos(latitude) * Math.sin(longitude)
  const y = radius * Math.sin(latitude)
  const z = radius * Math.cos(latitude) * Math.cos(longitude)

  const ref = useRef<MeshProps>(null)

  useEffect(() => {
    if (ref.current === null) return
    ref.current.lookAt!(0, 0, 0)
  }, [ref.current])

  return (
    <mesh
      position={[x, y, z]}
      rotation={[0, 0, 0]}
      matrix={new THREE.Matrix4().makeTranslation(0, 0, -0.4)}
      ref={ref}
      onClick={() => {
        onClick(revivart)
      }}
      onPointerOver={(e) => {
        setHovered(true)
      }}
      onPointerOut={(e) => {
        setHovered(false)
      }}
      scale={hovered ? 3 : 1}
    >
      <boxGeometry args={[0.005, 0.005, 1]} />
      <meshBasicMaterial color={hovered ? "red" : "#00fff5"} />
    </mesh>
  )
}

interface AllPostersProps {
  onRevivartClick: (poster: Poster | undefined) => void
}

const AllPosters: React.FC<AllPostersProps> = ({ onRevivartClick }): JSX.Element => {
  const [userRevivart, setUserRevivart] = useState<Poster[]>([])
  const db = getFirestore(firebaseApp)
  useEffect(() => {
    const users = query(collection(db, "userPosters"))
    getDocs(users)
      .then((usersDocs) => {
        let userPosters: Poster[] = []
        usersDocs.forEach((posterDocument) => {
          const posterData = posterDocument.data() as Poster
          userPosters.push(posterData)
        })
        setUserRevivart(userPosters)
      })
      .catch((error) => {
        console.log("Error getting document:", error)
      })
  }, [db])

  return (
    <group>
      {userRevivart.map((value) => {
        return <Location key={value.uid} lat={value.latitude} lon={value.longitude} revivart={value} onClick={onRevivartClick} />
      })}
    </group>
  )
}

export const VisualComponent: React.FC<VisualComponentProps> = ({ onRevivartClick }): JSX.Element => {
  return (
    <div className={styles.CanvasContainer}>
      <Canvas style={{ height: "800px" }}>
        <OrbitControls makeDefault enableZoom={false} />
        <Suspense fallback={null}>
          <Globe onRevivartClick={onRevivartClick} />
        </Suspense>
      </Canvas>
    </div>
  )
}
