import styles from "../product/index.module.css"
import mainStyles from "../../styles/App.module.css"
import { Route, Routes } from "react-router-dom"
import { setTabTitle } from "../../App"
import React from "react"
import { CategoryList } from "./category"
import { CreateProduct } from "./create"

export const Main: React.FC<{}> = (): JSX.Element => {
  setTabTitle("Admin Create")

  return (
    <div className={styles.root}>
      <div> Admin Category Console</div>
      <div className={mainStyles.MainLogo} />
      <div className={styles.AccountContentContainer}>
        <Routes>
          <Route path="/*" element={<CategoryList />} />
          <Route path="/:categoryID" element={<CreateProduct />} />
        </Routes>
      </div>
    </div>
  )
}

export default Main
