import styles from "../tobennawes/index.module.css"
import { useContext, useEffect, useRef, useState } from "react"
import { Product, ProductVariant } from "schema/dist/src/product"
import { AppContext, firebaseApp, setTabTitle } from "../../App"
import { collection, doc, getDocs, getFirestore, query, setDoc, where } from "firebase/firestore"
import { getDownloadURL, getStorage, ref } from "firebase/storage"
import { CartItem, ProductToCartItem } from "schema/dist/src/cart"
import { useLocation, useNavigate } from "react-router-dom"
import { BackURLKey, SizeToDimensions } from "../product"
import loadingImage from "./../../assets/loadingImage.png"
import { Category } from "schema/dist/src/category"
import { returnPageKey } from "../../auth"
import { PosterType, SelectPosterType, Poster } from "../tobennawes"

const pageID = "burnaboy"
export const Main: React.FC<{}> = (): JSX.Element => {
  const [posterType, setPosterType] = useState<PosterType>("AR")

  const { setNavType } = useContext(AppContext)
  useEffect(() => {
    setNavType("sleek")
    return () => {
      setNavType("bar")
    }
  }, [])

  return (
    <div className={styles.root}>
      <div className={styles.SectionVideoContainer}>
        <div className={styles.SectionVideoContent}>
          <div className={styles.BurnaboyHeaderSection}>
            <div className={styles.HeaderSectionLogo} />
            Order Burnaboy Poster
          </div>
        </div>
      </div>
      <SelectPosterType
        posterType={posterType}
        onClick={setPosterType}
        selectorStyle={styles.SelectPosterTypeContainerBurnaBoy}
      />
      <PosterSection posterType={posterType} />
    </div>
  )
}
export default Main

interface PosterSectionProps {
  posterType: PosterType
}
export const PosterSection: React.FC<PosterSectionProps> = ({ posterType }): JSX.Element => {
  const [shopItems, setShopItems] = useState<Product[]>([])
  const db = getFirestore(firebaseApp)

  useEffect(() => {
    setTabTitle("Tobenna Wes Art")
    const docRef = collection(db, "shopProducts")
    const catRef = collection(db, "shopCategories")
    const catQuery = query(catRef, where("pageID", "==", pageID))

    getDocs(catQuery)
      .then((snap) => {
        const category = snap.docs.map((doc) => {
          return doc.data() as Category
        })

        console.log(category, pageID)
        return category[0]
      })
      .then((doc) => {
        let postersQuery = query(docRef, where("category", "==", doc.uid), where("isActive", "==", true))
        if (posterType === "regular") {
          postersQuery = query(postersQuery, where("typeId", "==", 1))
        } else if (posterType === "AR") {
          postersQuery = query(postersQuery, where("typeId", "==", 2))
        }

        return getDocs(postersQuery)
      })
      .then((snap) => {
        const products = snap.docs.map((doc) => {
          return doc.data() as Product
        })

        setShopItems([...products])
      })
      .catch((error) => {
        console.log("Error getting document:", error)
      })
  }, [db, posterType])

  return (
    <div className={styles.PosterSection}>
      {shopItems.map((val) => {
        return <Poster product={val} key={val.uid} />
      })}
    </div>
  )
}
