import React, { useEffect, useRef, useState } from "react"
import styles from "./index.module.css"
import * as THREE from "three"
import { Suspense } from "react"
import { Canvas, useFrame } from "@react-three/fiber"
import { OrbitControls, Text, useTexture, Html } from "@react-three/drei"
import RevivarLogo from "../../assets/logowhite.png"
import RevivarStickerLogo from "../../assets/stickerLogo.png"
import { getDownloadURL, getStorage, ref } from "firebase/storage"
import { PosterData } from "../shared/schema"

interface Polaroid3DProps {
  polaroids: PosterData[]
  polaroidsInView: number
  onSelected?: (a: number) => void
  playVideo: boolean
  showVideo: boolean
  previewTime?: number
}

export const Polaroid3D: React.FC<Polaroid3DProps> = ({
  polaroids,
  polaroidsInView,
  onSelected,
  playVideo,
  showVideo,
  previewTime,
}): JSX.Element => {
  return (
    <div className={styles.PolaroidImmersedContainer}>
      <Canvas dpr={[1, 2]} camera={{ fov: 400, position: [0, 0, 200] }}>
        <directionalLight intensity={1} />
        <ambientLight intensity={1} />
        {/* <OrbitControls makeDefault enableZoom={false} /> */}
        <Suspense fallback={null}>
          {polaroids.map((val, i) => {
            const isCurrent = i === polaroidsInView
            return (
              <PolaroidContainer
                key={val.uid}
                polaroid={val}
                videoIsPlaying={isCurrent ? playVideo : false}
                position={i}
                polaroidsInView={polaroidsInView}
                onSelected={onSelected}
                previewTime={isCurrent ? previewTime : undefined}
                showVideo={isCurrent ? showVideo : false}
              />
            )
          })}
        </Suspense>
      </Canvas>
    </div>
  )
}

interface PolaroidContainerProps {
  polaroid: PosterData
  polaroidsInView?: number
  onSelected?: (a: number) => void
  position: number
  videoIsPlaying: boolean
  showVideo: boolean
  previewTime?: number
}

export const PolaroidContainer: React.FC<PolaroidContainerProps> = ({
  polaroid,
  position,
  polaroidsInView,
  onSelected = () => {},
  videoIsPlaying,
  previewTime,
  showVideo,
}): JSX.Element => {
  const offset = position - polaroidsInView!
  let zOffset = Math.abs(offset)

  const polRef = useRef<any>()

  const defPosition = new THREE.Vector3(offset * 150, 0, -zOffset * 50)

  const [positionVe] = useState<THREE.Vector3>(defPosition)

  useFrame((state) => {
    if (polRef.current === undefined) return

    polRef.current.position.x = THREE.MathUtils.lerp(polRef.current.position.x, offset * 150, 0.05)
    polRef.current.position.z = THREE.MathUtils.lerp(polRef.current.position.z, -zOffset * 50, 0.05)
  })

  return (
    <mesh
      position={positionVe}
      rotation={[0, 0, 0]}
      ref={polRef}
      onClick={() => {
        onSelected(position)
      }}
    >
      <boxGeometry args={[100, 120, 0.1]} />
      <meshBasicMaterial color={"white"} />
      <PolaroidPlaceHolder />
      <PolaroidImageHTML imageUrl={polaroid.imageURL} hideImage={showVideo} />
      <PolaroidVideoHTML videoURL={polaroid.videoURL} playVideo={videoIsPlaying} previewTime={previewTime} />
      <RevivarSticker />
      {/* {children} */}
    </mesh>
  )
}

interface PolaroidVideoProps {
  videoURL: string
  playVideo: boolean
  previewTime?: number
}

export const PolaroidVideoHTML: React.FC<PolaroidVideoProps> = ({ videoURL, playVideo, previewTime }): JSX.Element => {
  const videoRef = useRef<HTMLVideoElement>(null)
  const storage = getStorage()
  const [actualURL, setActualURL] = useState("")

  useEffect(() => {
    if (videoURL.includes("gs://")) {
      const imageStorageRef = ref(storage, videoURL)

      getDownloadURL(imageStorageRef).then((downloadURL) => {
        setActualURL(downloadURL)
      })
    } else {
      setActualURL(videoURL)
    }
  }, [videoURL])

  useEffect(() => {
    if (actualURL === "") {
      return
    }

    if (playVideo) {
      videoRef.current?.play()
    } else {
      videoRef.current?.pause()
    }
  }, [playVideo, actualURL])

  useEffect(() => {
    if (actualURL === "") {
      return
    }

    if (previewTime === undefined) return

    videoRef.current?.pause()
    videoRef.current!.currentTime = previewTime
    console.log("end", previewTime)
  }, [previewTime])

  if (actualURL === "") {
    return <group></group>
  }

  return (
    <group position={[0, 10, 3]} scale={10} visible={false}>
      <Html scale={1} transform occlude pointerEvents="none" center visible={false}>
        <div className={styles.VideoFibre}>
          <video
            width="100%"
            height="100%"
            controls={false}
            autoPlay={false}
            src={actualURL}
            loop
            playsInline
            muted
            ref={videoRef}
          />
        </div>
      </Html>
    </group>
  )
}

export const PolaroidPlaceHolder: React.FC<{}> = (): JSX.Element => {
  let texture = useTexture(RevivarLogo)

  return (
    <mesh position={[0, 10, 0.5]}>
      <boxGeometry args={[90, 90, 0.1]} />
      <meshBasicMaterial color={"black"} />
      <mesh position={[0, 0, 0.7]}>
        <boxGeometry args={[40, 40, 0]} />
        <meshStandardMaterial
          attach="material"
          map={texture}
          color={"#ffffff"}
          metalness={0.5}
          emissive={new THREE.Color(0xffffff)}
          emissiveMap={texture}
          transparent={true}
        />
      </mesh>
    </mesh>
  )
}

export const RevivarSticker: React.FC<{}> = (): JSX.Element => {
  let texture = useTexture(RevivarStickerLogo)
  const [hovered, setHovered] = useState(false)
  const c = new THREE.Color("#000000")

  const textRef = useRef<any>()

  useFrame((state) => {
    if (textRef === null || textRef === undefined) {
      return
    }

    textRef.current!.scale.y = THREE.MathUtils.lerp(textRef.current!.scale.y, hovered ? 2 : 0, 0.1)

    textRef.current!.scale.x = THREE.MathUtils.lerp(textRef.current!.scale.x, hovered ? 2 : 0, 0.1)
  })

  return (
    <mesh
      position={[0, 10, -0.5]}
      onPointerOver={(e) => {
        e.stopPropagation()
        setHovered(true)
      }}
      onPointerOut={() => {
        setHovered(false)
      }}
    >
      <boxGeometry args={[40, 40, 0]} />
      <meshStandardMaterial
        attach="material"
        map={texture}
        color={"black"}
        metalness={0.5}
        emissive={new THREE.Color(0xffffff)}
        emissiveMap={texture}
        transparent={true}
      />

      <Text
        maxWidth={0.6}
        anchorX="left"
        anchorY="top"
        position={[-10, -20, -0.1]}
        fontSize={5}
        rotation={[0, Math.PI, 0]}
        color={c}
        ref={textRef}
      >
        Sticker Tag
      </Text>
    </mesh>
  )
}

interface PolaroidImageProps {
  imageUrl: string
  hideImage: boolean
}

export const PolaroidImageHTML: React.FC<PolaroidImageProps> = ({ imageUrl, hideImage }): JSX.Element => {
  const storage = getStorage()
  const [actualURL, setActualURL] = useState("")
  useEffect(() => {
    if (imageUrl.includes("gs://")) {
      const imageStorageRef = ref(storage, imageUrl)

      getDownloadURL(imageStorageRef).then((downloadURL) => {
        setActualURL(downloadURL)
      })
    } else {
      setActualURL(imageUrl)
    }
  }, [imageUrl])

  if (imageUrl === "" || hideImage) {
    return <group></group>
  }

  return (
    <group position={[0, 10, 5]} scale={10} visible={false}>
      <Html scale={1} transform occlude pointerEvents="none" center visible={false}>
        <div className={styles.ImageFibre} style={{ backgroundImage: "url(" + actualURL + ")" }}></div>
      </Html>
    </group>
  )
}
