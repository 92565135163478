import { collection, doc, getFirestore, setDoc } from "firebase/firestore"
import { ProductVariant } from "schema/dist/src/product"
import { uploadFile } from "../../admin/product/create"
import { firebaseApp } from "../../App"

import { StudioSessionAssets, StudioSession } from "schema/dist/src/studio"

export function UploadToAssetLocation(
  asset: StudioSessionAssets,
  name: string,
  studio: StudioSession,
  file: File | Blob,
  fileType: "video" | "image",
  onUpdate: (a: number) => void
) {
  return uploadFile(asset.posterUID + name, `/studioSession/${studio.uid}/`, file, onUpdate, (a, ref) => {}).then((ref) => {
    if (fileType === "video") {
      asset.videoURL = ref.dest
    } else {
      asset.imageURL = ref.dest
    }

    return updateStudio(studio).then(() => {
      return ref.dest
    })
  })
  // .then((ref) => {
  //   console.log("Done")
  // })
  // .catch(() => {})
  // .finally(() => {
  //   // setUploadTitle("UPLOAD")
  // })
}

export function updateStudio(studio: StudioSession) {
  const db = getFirestore(firebaseApp)
  const sessionCollRef = collection(db, "studioSessions")
  const sessionRef = doc(sessionCollRef, studio.uid)

  return setDoc(sessionRef, studio)
}

export function CloseStudioSession(studio: StudioSession) {
  const db = getFirestore(firebaseApp)
  const sessionCollRef = collection(db, "studioSessions")
  const sessionRef = doc(sessionCollRef, studio.uid)

  return setDoc(sessionRef, { editable: false }, { merge: true })
}

export class PosterData {
  videoURL: string
  imageURL: string
  uid: string
  rerender?: boolean
  constructor(videoURL: string, imageURL: string, uid: string) {
    this.videoURL = videoURL
    this.imageURL = imageURL
    this.uid = uid
    this.rerender = false
  }
}
