import { useNavigate } from "react-router-dom"
import styles from "./index.module.css"
import museum from "../assets/video/museum.mp4"
import polaroid from "../assets/video/polaroidstack.mp4"
import MonaVideo from "../assets/video/mona_2.mp4"

export const Main: React.FC<{}> = (): JSX.Element => {
  return (
    <div className={styles.LandingRoot}>
      <PosterView />
      <DoubleStack>
        <VideoViewBox title="Create Revivar Poster" buttonTitle="Create" location="/studio/poster" videoURL={MonaVideo} />
        <VideoViewBox title="Create your Revivar Polaroid" buttonTitle="Create" location="/studio/polaroid" videoURL={polaroid} />
      </DoubleStack>
      <DoubleStack>
        <SingleStack
          title="Create Wedding Invite"
          buttonTitle="Contact us"
          location="/contactus"
          backgroundClass={styles.WeddingBackgroundImage}
        />
      </DoubleStack>
      <DoubleStack>
        <SingleStack
          title="Create and Ship PostCard"
          buttonTitle="Coming soon"
          backgroundClass={styles.PostCardBackgroundImage}
        />
        <SingleStack
          title="Request QR Image"
          buttonTitle="Request"
          location="/generateqr"
          backgroundClass={styles.ScanRequestBackgroundImage}
        />
      </DoubleStack>
    </div>
  )
}
export default Main

export const PosterView: React.FC<{}> = (): JSX.Element => {
  const nav = useNavigate()

  return (
    <div className={styles.Section}>
      <div className={styles.SectionVideoContainer}>
        <video loop autoPlay muted playsInline src={museum}></video>
        <div className={styles.SectionVideoContent}>
          <div className={styles.TobennaWesArtLogo}></div>
          <div className={styles.ShopButtonContainer}>
            <div
              className={styles.ShopButton}
              onClick={() => {
                nav("/shop/tobennawes")
              }}
            >
              Shop Posters
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export const VideoViewBox: React.FC<{ videoURL: string; title: string; location: string; buttonTitle: string }> = ({
  videoURL,
  title,
  location,
  buttonTitle,
}): JSX.Element => {
  const nav = useNavigate()
  return (
    <div className={styles.Section}>
      <div className={styles.SectionVideoContainer}>
        <video loop autoPlay muted playsInline src={videoURL}></video>
        <div className={styles.SectionVideoContent}>
          <br />
          <h2>{title}</h2>
          <div className={styles.ShopButtonContainer}>
            <div
              className={styles.ShopButton}
              onClick={() => {
                nav(location)
              }}
            >
              {buttonTitle}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export const DoubleStack: React.FC<{}> = ({ children }): JSX.Element => {
  return <div className={styles.SectionStacker}>{children}</div>
}

interface SingleStackProps {
  title: string
  buttonTitle: string
  location?: string
  backgroundClass: string
}
export const SingleStack: React.FC<SingleStackProps> = ({ title, buttonTitle, location, backgroundClass }): JSX.Element => {
  const nav = useNavigate()
  const backgroundClassName = styles.BackgroundImage + " " + backgroundClass

  return (
    <div className={styles.Section}>
      <div className={backgroundClassName}>
        <br />
        <h2>{title}</h2>
        <div className={styles.ShopButtonContainer}>
          <div
            className={styles.ShopButton}
            onClick={() => {
              if (location === undefined) return
              nav(location)
            }}
          >
            {buttonTitle}
          </div>
        </div>
      </div>
    </div>
  )
}
