import React from "react"
import styles from "./styles/home.module.css"

export const Main: React.FC<{}> = (): JSX.Element => {
  return (
    <div className={styles.root}>
      <div className={styles.ContactUsRoot}> Contact us</div>
      <div className={styles.ContactUsMain}>
        <div className={styles.ContactUsMainSales}> </div>
        <span>
          <h1>Talk with a member of our team</h1>
          Need to get in touch? We’ll help create you the perfect revivar product and answer any questions you have
          <h2
            onClick={() => {
              window.location.href = "mailto:support@revivar.app"
            }}
          >
            Contact Us
          </h2>
        </span>
      </div>
    </div>
  )
}
export default Main
