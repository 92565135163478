import styles from "./create.module.css"
import { Routes, Route } from "react-router-dom"
// import Polaroid, { PreviewPolaroids } from "../../../yard/createPolaroid"
import PosterPage from "./poster/"

import PolaroidPage from "./polaroid/"
import { useContext, useEffect } from "react"
import { AppContext } from "../App"

export const Main: React.FC<{}> = (): JSX.Element => {
  const { setNavType } = useContext(AppContext)
  useEffect(() => {
    setNavType("sleek")
    return () => {
      setNavType("bar")
    }
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className={styles.root}>
      <Routes>
        <Route path="/polaroid" element={<PolaroidPage />} />
        <Route path="/polaroid/:sessionID" element={<PolaroidPage />} />
        <Route path="/poster" element={<PosterPage />} />
        <Route path="/poster/:sessionID" element={<PosterPage />} />
      </Routes>
    </div>
  )
}
export default Main
