import { collection, deleteDoc, doc, getDoc, getDocs, getFirestore, onSnapshot, setDoc } from "firebase/firestore"
import { getDownloadURL, getStorage, ref } from "firebase/storage"
import { useContext, useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { AppContext, firebaseApp } from "../App"
import { SetOrderSession } from "../create/createRevivart"
import { isFailedKey, StartCheckOut } from "../create/createUrl"
import { CartItem, ProductToCartItem } from "schema/dist/src/cart"
import { isProductVariantActive, Product, ProductVariant } from "schema/dist/src/product"
import { OrderDetails } from "schema/dist/src/order"
import styles from "./cart.module.css"
import loadingImage from "./../assets/loadingImage.png"
import { BackURLKey } from "../shop/product"
import { ValidateUserCheckout } from "../auth"
import { User } from "firebase/auth"

export const Main: React.FC<{}> = (): JSX.Element => {
  const db = getFirestore(firebaseApp)
  const [cartList, setCartList] = useState<CartItem[]>([])
  const nav = useNavigate()
  const loc = useLocation()
  const [isLoading, setIsLoading] = useState(false)

  const search = useLocation().search
  // TODO:: SET CHECKOUT ANIMATION
  const [checkoutText, setCheckoutText] = useState("Checkout")
  const { user, userToken } = useContext(AppContext)

  useEffect(() => {
    if (user === null) {
      return
    }

    const posterRefParent = doc(db, "userData", user.uid)
    const newPosterCol = collection(posterRefParent, "cartItems")
    const orderCollection = collection(posterRefParent, "orderDetails")

    const done = onSnapshot(newPosterCol, (snap) => {
      const CartItem = snap.docs.map((doc) => {
        return doc.data() as CartItem
      })

      setCartList(CartItem)
    })

    const isFailed = new URLSearchParams(search).get(isFailedKey) ?? ""
    const orderID = new URLSearchParams(search).get("orderID") ?? ""

    if (isFailed === "true" && orderID !== "") {
      const orderDocRef = doc(orderCollection, orderID)

      // WE FIRST HAVE TO CHECK THAT THE ORDER DETAILS IS STILL ACTIVE
      getDoc(orderDocRef)
        .then((snap) => {
          if (!snap.exists) {
            console.log("session has already been removed")
          }
          const det = snap.data() as OrderDetails
          if (det.paymentStatus.toLowerCase() !== "paid") {
            return deleteDoc(orderDocRef)
          }
        })
        .then(() => {
          console.log("deleted last order session")
        })
    }

    return () => {
      done()
    }
  }, [user, db, search])

  let totalAmount = 0
  cartList.forEach((item) => {
    totalAmount += item.count * item.productVariant.price
  })

  function StartCheckout() {
    setIsLoading(true)
    if (ValidateUserCheckout(user, nav, loc)) {
      if (user === null || isLoading) {
        return
      }
      SetOrderSession(cartList, user.uid)
        .then((orderID: string | undefined) => {
          setCheckoutText("Loading Order")
          return StartCheckOut(orderID, user.uid, userToken)
        })
        .then(() => {})
        .finally(() => {
          setCheckoutText("Checkout")
          setIsLoading(false)
        })
    }
  }

  // TODO:: ADD EMPTY PAGE
  // TOOD:: CACHE IMAGES
  return (
    <div className={styles.root}>
      <div className={styles.CartContainer}>
        <b>My Shopping Cart</b>
        {cartList.map((val, i) => {
          return <CartItemBar cartData={val} key={val.uid} userID={user?.uid ?? ""} />
        })}
      </div>
      <div className={styles.CartContainerTotal}>
        <div className={styles.CartContainerTotalItem}>
          SubTotal: <b>{"$" + totalAmount.toFixed(2)}</b>
        </div>
        <div className={styles.CartContainerTotalItem}>
          Tax: <b>{"$--"}</b>
        </div>
        <div className={styles.CartContainerTotalItem}>
          Shipping: <b>{"$--"}</b>
        </div>
      </div>
      <div className={styles.CartContainerControls}>
        <div
          className={styles.CartContainerButton}
          onClick={() => {
            nav("/shop")
          }}
        >
          Continue Shopping
        </div>
        {cartList.length > 0 ? (
          <div className={styles.CartContainerButton} onClick={StartCheckout}>
            {isLoading ? <div className={styles.CartContainerButtonIcon}></div> : <>{checkoutText}</>}
          </div>
        ) : null}
      </div>
    </div>
  )
}
export default Main

export const CartItemBar: React.FC<{ cartData: CartItem; userID: string }> = ({ cartData, userID }): JSX.Element => {
  const [imageURL, setImageURL] = useState(loadingImage)
  const db = getFirestore(firebaseApp)
  const [totalItems, setTotalItems] = useState(cartData.count)
  const nav = useNavigate()
  const loc = useLocation()
  const [productData, setProductData] = useState<Product>()
  const countList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
  const isInList = countList.find((val) => {
    return val === totalItems
  })

  useEffect(() => {
    const db = getFirestore(firebaseApp)
    const docRef = doc(db, "shopProducts", cartData.productUid ?? "")
    getDoc(docRef).then((docSnap) => {
      if (docSnap.exists()) {
        const product = docSnap.data() as Product
        setProductData(product)
      } else {
        console.log("No such document!")
      }
    })

    if (cartData.count !== totalItems) {
      setTotalItems(cartData.count)
    }

    if ((cartData.productImageUrl ?? "") === "") return
    const storage = getStorage()
    getDownloadURL(ref(storage, cartData.productImageUrl))
      .then((url) => {
        setImageURL(url)
      })
      .catch((error) => {
        console.error(error)
      })
  }, [cartData])

  function DeleteCartItem() {
    if (userID === "") {
      return
    }

    const posterRefParent = doc(db, "userData", userID)
    const docRef = doc(collection(posterRefParent, "cartItems"), cartData.uid)

    deleteDoc(docRef)
      .then(() => {})
      .catch((e) => {
        console.error(e)
      })
  }

  function UpdateCartCount(str: string) {
    const oldCount = totalItems
    let count = parseInt(str, 10)
    //MAX ORDER COUNT IS 100
    if (isNaN(count) || count >= 100 || count === 0) {
      count = 1
    }

    const posterRefParent = doc(db, "userData", userID)
    const docRef = doc(collection(posterRefParent, "cartItems"), cartData.uid)

    setTotalItems(count)

    //TODO:: BETTER ERROR HANDLING
    setDoc(docRef, { count: count }, { merge: true })
      .then(() => {})
      .catch((e) => {
        // RETURN IT BACK TO OLD
        setTotalItems(oldCount)
      })
  }

  return (
    <div className={styles.CartItemBar}>
      <img
        className={styles.CartItemImage}
        src={imageURL}
        alt={"Cart Icon"}
        onClick={() => {
          if (cartData.productType === "shopProduct") {
            if ((productData?.studioID ?? "") !== "") {
              nav(`/studio/${productData?.studioID}/${cartData.productVariant.studioSessionID}`)
              return
            }
            nav(`/shop/product/${cartData.productUid}?${BackURLKey}=${loc.pathname}`)
          } else if (cartData.productType === "userPoster") {
            //TODO:: OPEN DETAILS USER PAGE
          }
        }}
      />
      <div className={styles.CartItemInfo}>
        <div>{cartData.title}</div>
        <div>{cartData.productVariant.size}</div>
        <div>${cartData.productVariant.price}</div>
      </div>
      <div className={styles.CartItemControls}>
        <div
          className={styles.CartItemDeleteIcon}
          onClick={(e) => {
            e.stopPropagation()
            DeleteCartItem()
          }}
        />

        <select
          onChange={(e) => {
            UpdateCartCount(e.target.value)
          }}
          defaultValue={totalItems}
        >
          {countList.map((number) => {
            return (
              <option key={number} value={number}>
                {number}
              </option>
            )
          })}
          {isInList ? null : (
            <option key={totalItems} value={totalItems}>
              {totalItems}
            </option>
          )}
        </select>
      </div>
    </div>
  )
}

export function GetCartItems(user: User) {
  const db = getFirestore(firebaseApp)

  const posterRefParent = doc(db, "userData", user.uid)
  const newPosterCol = collection(posterRefParent, "cartItems")
  return getDocs(newPosterCol).then((snap) => {
    const CartItem = snap.docs.map((doc) => {
      return doc.data() as CartItem
    })

    return CartItem
  })
}

export function WriteCartItems(user: User, cartItems: CartItem[]) {
  const allRefs = cartItems.map((cartItem) => {
    return AddToCartCartItem(user.uid, cartItem)
  })

  return Promise.all(allRefs)
}

export function AddToCart(userID: string, product: Product, variant: ProductVariant, count?: number) {
  const productCount = count ?? 1
  const db = getFirestore(firebaseApp)
  const posterRefParent = doc(db, "userData", userID)
  const newPosterCol = collection(posterRefParent, "cartItems")
  const newCartRef = doc(newPosterCol)
  let newCartItem = ProductToCartItem(product, newCartRef.id, variant, productCount, "shopProduct")

  return getDocs(newPosterCol).then((snap) => {
    // CHECK IF DOC EXIST
    if (snap.empty) {
      return setDoc(newCartRef, newCartItem)
    }

    snap.docs.forEach((doc) => {
      const cartData = doc.data() as CartItem

      if (cartData.productUid === product.uid && isProductVariantActive(cartData.productVariant, variant)) {
        cartData.count = cartData.count + productCount
        newCartItem = cartData
      }
    })

    // SET IT TO OLD CART ID
    let oldCartRef = doc(newPosterCol, newCartItem.uid)
    return setDoc(oldCartRef, newCartItem)
  })
}

export function AddToCartCartItem(userID: string, cartItem: CartItem) {
  const db = getFirestore(firebaseApp)
  const posterRefParent = doc(db, "userData", userID)
  const newPosterCol = collection(posterRefParent, "cartItems")
  const newCartRef = doc(newPosterCol)
  cartItem.uid = newCartRef.id

  return getDocs(newPosterCol).then((snap) => {
    // CHECK IF DOC EXIST
    if (snap.empty) {
      return setDoc(newCartRef, cartItem)
    }

    snap.docs.forEach((doc) => {
      const cartData = doc.data() as CartItem
      if (cartData.productUid === cartItem.productUid && cartData.productVariant.size === cartItem.productVariant.size) {
        cartData.count = cartData.count + cartItem.count

        console.log(cartData, cartItem)
        cartItem = cartData
      }
    })

    // SET IT TO OLD CART ID
    let oldCartRef = doc(newPosterCol, cartItem.uid)
    return setDoc(oldCartRef, cartItem)
  })
}
