import styles from "./index.module.css"
import mainStyles from "../../styles/App.module.css"

import { useEffect, useState } from "react"
import { getAuth, User } from "firebase/auth"
import NavBar from "../../navBar/NavBar"

import { Route, Routes, useLocation, useNavigate, useParams } from "react-router-dom"

import { firebaseApp, setTabTitle } from "../../App"
import { Poster } from "schema/dist/src/poster"

import React from "react"
import { collection, doc, getDoc, getFirestore, onSnapshot, orderBy, query, setDoc } from "firebase/firestore"
import { OrderDetails, OrderStatus } from "schema/dist/src/order"
import { ProductList } from "./product"
import { CreateProduct } from "./create"

export const Main: React.FC<{}> = (): JSX.Element => {
  setTabTitle("Admin Create")

  return (
    <div className={styles.root}>
      <div> Admin Create Console</div>
      <div className={mainStyles.MainLogo} />
      <div className={styles.AccountContentContainer}>
        <Routes>
          <Route path="/*" element={<ProductList />} />
          <Route path="/:productID" element={<CreateProduct />} />
        </Routes>
      </div>
    </div>
  )
}

export default Main
