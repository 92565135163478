import styles from "../product/index.module.css"
import { useEffect, useRef, useState } from "react"
import loadingImage from "./../../assets/loadingImage.png"
import { useNavigate, useParams } from "react-router-dom"
import { firebaseApp } from "../../App"
import React from "react"
import { deleteDoc, doc, getFirestore, onSnapshot, setDoc } from "firebase/firestore"
import { deleteObject, getDownloadURL, getStorage, ref } from "firebase/storage"
import { Category } from "schema/dist/src/category"
import { uploadFile } from "../product/create"

type RouteParams = {
  categoryID: string
}

export const CreateProduct: React.FC<{}> = (): JSX.Element => {
  const { categoryID } = useParams<RouteParams>()

  const [selectedCategory, setSelectedCategory] = useState<Category>()

  useEffect(() => {
    if (categoryID === undefined) return
    const db = getFirestore(firebaseApp)

    const orderDetailsRef = doc(db, "shopCategories", categoryID)

    const done = onSnapshot(orderDetailsRef, (response) => {
      const orderFinal = response.data() as Category
      setSelectedCategory(orderFinal)
    })

    return () => {
      done()
    }
  }, [categoryID])

  if (selectedCategory === undefined) {
    return <></>
  }

  return (
    <>
      <UploadMainSection category={selectedCategory} />
      <SetTitle category={selectedCategory} />
    </>
  )
}

export const UploadMainSection: React.FC<{
  category: Category
}> = ({ category }): JSX.Element => {
  const [resourceURL, setResourceURL] = useState(loadingImage)
  const [resourceBlob, setResourceBlob] = useState<File | Blob>()
  const [uploadTitle, setUploadTitle] = useState("UPLOAD")

  const hiddenFileInput = useRef<HTMLInputElement>(null)

  function handleResourceClicked(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.files !== null) {
      const file = e.target.files[0]
      setResourceBlob(file)
      const imageURL = URL.createObjectURL(file)
      setResourceURL(imageURL)
    }
  }

  function uploadResource() {
    if (resourceBlob === undefined) {
      setUploadTitle("SELECT FILE")
      return
    }

    const fileName = "LDARimage.jpg"

    uploadFile(
      fileName,
      `/categoryImages/${category?.uid}/`,
      resourceBlob,
      (a: number) => {
        setUploadTitle(a.toPrecision(3) + "%")
      },
      (a, ref) => {
        category.imageURL = a
        SetUpdateShopCategory(category)
      }
    )
      .then((ref) => {})
      .catch(() => {})
      .finally(() => {
        setUploadTitle("UPLOAD")
      })
  }

  useEffect(() => {
    if (category === undefined) return
    getVideoData(category)
  }, [category])

  function getVideoData(category: Category) {
    const storage = getStorage()
    const linkRef = category.imageURL

    if (linkRef === "") return
    getDownloadURL(ref(storage, linkRef))
      .then((url) => {
        setResourceURL(url)
      })
      .catch((error) => {
        console.error(error)
      })
  }

  if (category === undefined) return <></>

  return (
    <div className={styles.UploadMainSection}>
      <input
        style={{ display: "none" }}
        ref={hiddenFileInput}
        type="file"
        accept={".png, .jpeg, .png"}
        onChange={handleResourceClicked}
      />
      <div
        className={styles.UploadMainSectionTop}
        onClick={() => {
          hiddenFileInput.current?.click()
        }}
      >
        <img src={resourceURL} alt={"create"}></img>
      </div>
      <div className={styles.UploadMainSectionBottom}>
        <div className={styles.UpdateOrderDetails}>
          <div className={styles.UpdateOrderDetailsList}>
            <div className={styles.UpdateOrderStatusButton} onClick={uploadResource}>
              {uploadTitle}
            </div>
            <div
              className={styles.UpdateOrderStatusButton}
              onClick={() => {
                getVideoData(category)
              }}
            >
              RESET
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export const SetTitle: React.FC<{
  category: Category
}> = ({ category }): JSX.Element => {
  const [title, setTitle] = useState(category?.title ?? "")
  const [pageID, setPage] = useState(category?.pageID ?? "")
  const nav = useNavigate()
  useEffect(() => {
    setTitle(category.title)
    setPage(category.pageID)
  }, [category])

  let classNameEnabled = styles.UpdateOrderStatusButton
  let classNameDisEnabled = classNameEnabled + " " + styles.UpdateOrderStatusButtonSelected

  return (
    <div className={styles.UpdateOrderDetails}>
      <div className={styles.UpdateOrderDetailsList}>
        <input
          className={styles.UpdateOrderStatusButton}
          onInput={(e) => {
            setTitle(e.currentTarget.value)
          }}
          value={title}
        ></input>
        <div
          className={styles.UpdateOrderStatusButton}
          onClick={() => {
            category.title = title
            SetUpdateShopCategory(category)
          }}
        >
          Update Title
        </div>
      </div>
      <div className={styles.UpdateOrderDetailsList}>
        <input
          className={styles.UpdateOrderStatusButton}
          onInput={(e) => {
            setPage(e.currentTarget.value)
          }}
          value={pageID}
        ></input>
        <div
          className={styles.UpdateOrderStatusButton}
          onClick={() => {
            category.pageID = pageID
            SetUpdateShopCategory(category)
          }}
        >
          Update Page
        </div>
      </div>
      <div className={styles.UpdateOrderDetailsList}>
        <div
          className={!category.isActive ? classNameEnabled : classNameDisEnabled}
          onClick={() => {
            category.isActive = true
            SetUpdateShopCategory(category)
          }}
        >
          ENABLED
        </div>
        <div
          className={category.isActive ? classNameEnabled : classNameDisEnabled}
          onClick={() => {
            category.isActive = false
            SetUpdateShopCategory(category)
          }}
        >
          DISABLED
        </div>
        <div
          className={classNameEnabled}
          onClick={() => {
            DeleteUpdateShopCategory(category)
              .then(() => {
                nav("../")
              })
              .catch(() => {})
              .finally(() => {})
          }}
        >
          DELETE
        </div>
      </div>
    </div>
  )
}

export function SetUpdateShopCategory(category: Category) {
  const db = getFirestore(firebaseApp)
  const orderDetailsRef = doc(db, "shopCategories", category.uid)

  return setDoc(orderDetailsRef, category)
}

export function DeleteUpdateShopCategory(category: Category) {
  const db = getFirestore(firebaseApp)
  const orderDetailsRef = doc(db, "shopCategories", category.uid)

  return deleteDoc(orderDetailsRef).then(() => {
    const storage = getStorage()
    return deleteObject(ref(storage, category.imageURL))
  })
}
