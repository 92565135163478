import styles from "./index.module.css"
import { useEffect, useState } from "react"
import * as THREE from "three"
import { Suspense } from "react"
import React, { useRef } from "react"
import { Canvas, useFrame } from "@react-three/fiber"
import { Html, OrbitControls, useTexture } from "@react-three/drei"
import RevivarLogo from "../../assets/logowhite.png"
import defaultImageFrame from "../../assets/Frame08.png"
import ImageFrame1 from "../../assets/Frame1.png"
import ImageFrame08 from "../../assets/Frame08.png"
import ImageFrame075 from "../../assets/Frame075.png"
import ImageFrame067 from "../../assets/Frame067.png"
import { getDownloadURL, getStorage, ref } from "firebase/storage"
import loadingImage from "./../../assets/loadingImage.png"

export type PosterViewState = "normal" | "expand" | "compare"
export interface ImageGalleryProps {
  videoURL: string
  imageURL: string
  ratio: number
  play: boolean
  setPlay: (a: boolean) => void
  rotate?: boolean
  previewTime?: number
  showVideo?: boolean
  viewState?: PosterViewState
}

export const ImageGallery: React.FC<ImageGalleryProps> = ({
  videoURL,
  imageURL,
  ratio,
  play,
  rotate = false,
  previewTime,
  showVideo,
  viewState = "normal",
  setPlay,
}): JSX.Element => {
  return (
    <div className={styles.ImageGalleryCanvas}>
      <Canvas dpr={[1, 2]} camera={{ fov: 400, position: [0, 0, 200] }}>
        <directionalLight intensity={1} />
        <ambientLight intensity={1} />
        <OrbitControls makeDefault enableZoom={false} />
        <Suspense fallback={null}>
          <PosterContainer
            imageURL={imageURL}
            videoURL={videoURL}
            ratio={ratio}
            play={play}
            rotate={rotate}
            previewTime={previewTime}
            showVideo={showVideo}
            viewState={viewState}
            setPlay={setPlay}
          />
        </Suspense>
      </Canvas>
    </div>
  )
}

export const PosterContainer: React.FC<ImageGalleryProps> = ({
  videoURL,
  imageURL,
  ratio,
  play,
  rotate,
  previewTime,
  showVideo,
  viewState,
  setPlay,
}): JSX.Element => {
  const posterRef = useRef<THREE.Mesh>(null)
  const videoRef = useRef<THREE.Mesh>(null)

  const defPosition = new THREE.Vector3(0, 0)
  const [positionVe] = useState<THREE.Vector3>(defPosition)

  const speed = 0.03

  useFrame((state) => {
    if (posterRef.current == null) return
    if (videoRef.current === null) return

    if (viewState === "expand") {
      posterRef.current.position.z = THREE.MathUtils.lerp(posterRef.current.position.z, -80, speed)
      posterRef.current.position.x = THREE.MathUtils.lerp(posterRef.current.position.x, -20, speed)
      posterRef.current.rotation.y = THREE.MathUtils.lerp(posterRef.current.rotation.y, 1.1, speed)
      videoRef.current.position.z = THREE.MathUtils.lerp(videoRef.current.position.z, 30, speed)
      videoRef.current.scale.x = THREE.MathUtils.lerp(videoRef.current.scale.x, 6, speed)
      videoRef.current.scale.y = THREE.MathUtils.lerp(videoRef.current.scale.y, 6, speed)
      videoRef.current.scale.z = THREE.MathUtils.lerp(videoRef.current.scale.z, 6, speed)
    } else if (viewState === "compare") {
      posterRef.current.position.z = THREE.MathUtils.lerp(posterRef.current.position.z, -200, speed)
      posterRef.current.position.x = THREE.MathUtils.lerp(posterRef.current.position.x, -50, speed)
      posterRef.current.rotation.y = THREE.MathUtils.lerp(posterRef.current.rotation.y, 0, speed)
      videoRef.current.position.z = THREE.MathUtils.lerp(videoRef.current.position.z, 1, speed)
      videoRef.current.position.x = THREE.MathUtils.lerp(videoRef.current.position.x, 100, speed)
      videoRef.current.scale.x = THREE.MathUtils.lerp(videoRef.current.scale.x, 7, speed)
      videoRef.current.scale.y = THREE.MathUtils.lerp(videoRef.current.scale.y, 7, speed)
      videoRef.current.scale.z = THREE.MathUtils.lerp(videoRef.current.scale.z, 7, speed)
    } else {
      posterRef.current.position.z = THREE.MathUtils.lerp(posterRef.current.position.z, 0, speed)
      posterRef.current.position.x = THREE.MathUtils.lerp(posterRef.current.position.x, 0, speed)
      posterRef.current.rotation.y = THREE.MathUtils.lerp(posterRef.current.rotation.y, 0, speed)
      videoRef.current.position.z = THREE.MathUtils.lerp(videoRef.current.position.z, 1, speed)
      videoRef.current.position.x = THREE.MathUtils.lerp(videoRef.current.position.x, 0, speed)
      videoRef.current.scale.x = THREE.MathUtils.lerp(videoRef.current.scale.x, 7, speed)
      videoRef.current.scale.y = THREE.MathUtils.lerp(videoRef.current.scale.y, 7, speed)
      videoRef.current.scale.z = THREE.MathUtils.lerp(videoRef.current.scale.z, 7, speed)

      if (!rotate) return
      posterRef.current.rotation.y = THREE.MathUtils.lerp(
        posterRef.current.rotation.y,
        Math.cos(state.clock.elapsedTime) / 3,
        0.05
      )
    }
  })

  return (
    <mesh
      position={positionVe}
      ref={posterRef}
      onPointerOver={(e) => {
        // BUG:: Presses Play twice
        // onPointerOver and onPointerOut called a lot of times
        setPlay(true)
        e.stopPropagation()
      }}
      onPointerOut={(e) => {
        setPlay(false)
        e.stopPropagation()
      }}
      scale={1.2}
    >
      <boxGeometry args={[100 * ratio, 100, 2]} />
      <meshBasicMaterial color={"#333333"} />
      <mesh position={new THREE.Vector3(0, 0, 1)} onClick={() => {}} scale={0.93}>
        <boxGeometry args={[100 * ratio, 100, 0.5]} />
        <meshStandardMaterial color="white" />
        <FrameImageHTML imageURL={""} hideImage={false} ratio={ratio} />
        <PosterPlaceHolder show={imageURL === "" && videoURL === ""} ratio={ratio} />
        <PosterImageHTML imageURL={imageURL} hideImage={(showVideo || play) && viewState === "normal"} ratio={ratio} />
        <PosterVideoHTML videoURL={videoURL} playVideo={play} ratio={ratio} previewTime={previewTime} videoPosterRef={videoRef} />
      </mesh>
    </mesh>
  )
}

interface PosterImageProps {
  hideImage: boolean
  ratio: number
  imageURL: string
}

export const PosterImageHTML: React.FC<PosterImageProps> = ({ hideImage, ratio, imageURL }): JSX.Element => {
  const [actualURL, setActualURL] = useState("")

  useEffect(() => {
    const storage = getStorage()

    if (imageURL.includes("gs://")) {
      const imageStorageRef = ref(storage, imageURL)
      getDownloadURL(imageStorageRef).then((downloadURL) => {
        setActualURL(downloadURL)
      })
    } else {
      setActualURL(imageURL)
    }
  }, [imageURL])

  if (actualURL === "" || hideImage) {
    return <group></group>
  }

  return (
    <group position={[0, 0, 2]} scale={7} visible={true}>
      <Html
        scale={1}
        transform
        occlude
        pointerEvents="none"
        center
        visible={false}
        style={{ height: "500px", width: `${ratio * 500}px` }}
      >
        <div className={styles.ImageFibre} style={{ backgroundImage: "url(" + actualURL + ")" }}></div>
      </Html>
    </group>
  )
}

interface PosterFrameRatio {
  ratio: 0.8 | 1 | 0.75 | 0.67
  imageURL: string
}

export function FindPosterFrame(frames: PosterFrameRatio[], ratio: number): string {
  const defaultFrame = defaultImageFrame
  const frameRatio = frames.find((val) => {
    return val.ratio === ratio
  })

  if (frameRatio !== undefined) {
    return frameRatio.imageURL
  }

  return defaultFrame
}

export const FrameImageHTML: React.FC<PosterImageProps> = ({ hideImage, ratio }): JSX.Element => {
  const Frames: PosterFrameRatio[] = [
    {
      ratio: 0.8,
      imageURL: ImageFrame08,
    },
    {
      ratio: 0.75,
      imageURL: ImageFrame075,
    },
    {
      ratio: 0.67,
      imageURL: ImageFrame067,
    },
    {
      ratio: 1,
      imageURL: ImageFrame1,
    },
  ]

  const imageURL = FindPosterFrame(Frames, ratio)

  if (imageURL === "" || hideImage) {
    return <group></group>
  }

  return (
    <group position={[0, 0, 3]} scale={8.5} visible={true}>
      <Html
        scale={1}
        transform
        occlude
        pointerEvents="none"
        center
        visible={false}
        style={{ height: "500px", width: `${ratio * 500}px` }}
      >
        <div className={styles.FrameImageFibre} style={{ backgroundImage: "url(" + imageURL + ")" }}></div>
      </Html>
    </group>
  )
}

interface PosterVideoProps {
  videoURL: string
  playVideo: boolean
  ratio: number
  previewTime?: number
  videoPosterRef: React.RefObject<THREE.Mesh>
}

export const PosterVideoHTML: React.FC<PosterVideoProps> = ({
  videoPosterRef,
  videoURL,
  ratio,
  playVideo,
  previewTime,
}): JSX.Element => {
  const videoRef = useRef<HTMLVideoElement>(null)
  const [actualURL, setActualURL] = useState("")

  useEffect(() => {
    const storage = getStorage()

    if (videoURL.includes("gs://")) {
      const imageStorageRef = ref(storage, videoURL)
      getDownloadURL(imageStorageRef).then((downloadURL) => {
        setActualURL(downloadURL)
      })
    } else {
      setActualURL(videoURL)
    }
  }, [videoURL])

  useEffect(() => {
    if (videoRef.current === undefined || videoRef.current === null) {
      return
    }
    videoRef.current!.src = actualURL
    // videoRef.current?.play()
  }, [actualURL, videoRef])

  useEffect(() => {
    if (actualURL === "" || videoRef.current === null) {
      return
    }

    if (playVideo) {
      videoRef.current.play()
    } else {
      videoRef.current.pause()
      videoRef.current.currentTime = 0
    }
  }, [playVideo, actualURL])

  useEffect(() => {
    if (actualURL === "") {
      return
    }

    if (previewTime === undefined) return
    videoRef.current?.pause()
    videoRef.current!.currentTime = previewTime
  }, [previewTime, actualURL])

  if (actualURL === "") {
    return <group ref={videoPosterRef}></group>
  }

  return (
    <group position={[0, 0, 1]} scale={7} visible={true} ref={videoPosterRef}>
      <Html
        scale={1}
        transform
        occlude
        pointerEvents="none"
        center
        visible={false}
        style={{ height: "500px", width: `${ratio * 500}px` }}
      >
        <div className={styles.VideoFibre}>
          <video
            width="100%"
            height="100%"
            ref={videoRef}
            src={actualURL}
            loop
            autoPlay
            muted
            playsInline
            poster={loadingImage}
          />
        </div>
      </Html>
    </group>
  )
}

interface PosterPlaceHolderProps {
  show: boolean
  ratio: number
}

export const PosterPlaceHolder: React.FC<PosterPlaceHolderProps> = ({ show, ratio }): JSX.Element => {
  let texture = useTexture(RevivarLogo)

  return (
    <mesh position={[0, 0, 0]} visible={show}>
      <boxGeometry args={[100 * ratio, 100, 0.5]} />
      <meshBasicMaterial color={"black"} />
      <mesh position={[0, 0, 0.7]}>
        <boxGeometry args={[40, 40, 0]} />
        <meshStandardMaterial
          attach="material"
          map={texture}
          color={"#ffffff"}
          metalness={0.5}
          emissive={new THREE.Color(0xffffff)}
          emissiveMap={texture}
          transparent={true}
        />
      </mesh>
    </mesh>
  )
}
