import productStyles from "../product/index.module.css"
import mainStyles from "../../styles/App.module.css"
import { Route, Routes } from "react-router-dom"
import { setTabTitle } from "../../App"
import React from "react"
import Create from "./create"

export const Main: React.FC<{}> = (): JSX.Element => {
  setTabTitle("Admin Create")

  return (
    <div className={productStyles.root}>
      <div> Admin Category Console</div>
      <div className={mainStyles.MainLogo} />
      <div className={productStyles.AccountContentContainer}>
        <Routes>
          <Route path="/*" element={<Create />} />
        </Routes>
      </div>
    </div>
  )
}

export default Main
