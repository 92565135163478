import React, { useEffect, useState } from "react"
import styles from "./index.module.css"
import { useNavigate } from "react-router-dom"
import { getDownloadURL, getStorage, ref } from "firebase/storage"
import { Poster } from "schema/dist/src/poster"
import { OrdersPagination } from "./overview"
import loadingImage from "./../assets/loadingImage.png"

interface OverviewProps {
  posterList: Poster[]
}

export const Gallery: React.FC<OverviewProps> = ({ posterList }): JSX.Element => {
  const [page, setPage] = useState(1)
  const pageCount = 8
  const posterListOrder = posterList.slice((page - 1) * pageCount, page * pageCount)

  return (
    <div className={styles.GalleryContainer}>
      <b>My Gallery</b>
      <div className={styles.GalleryAssetContainer}>
        {posterListOrder.map((posterItem) => {
          return <GalleryAsset key={posterItem.uid} poster={posterItem} />
        })}
      </div>

      <OrdersPagination totalPages={posterList.length / pageCount} currentPage={page} setPage={setPage} />
    </div>
  )
}

export default Gallery

export const GalleryAsset: React.FC<{ poster: Poster }> = ({ poster }): JSX.Element => {
  const [actualURL, setActualURL] = useState(loadingImage)
  const nav = useNavigate()

  useEffect(() => {
    const storage = getStorage()
    if (poster.imageLDURL.includes("gs://")) {
      const imageStorageRef = ref(storage, poster.imageLDURL)
      getDownloadURL(imageStorageRef).then((downloadURL) => {
        setActualURL(downloadURL)
      })
    } else {
      setActualURL(poster.imageLDURL ?? "")
    }
  }, [poster.imageLDURL])
  return (
    <div
      className={styles.GalleryAsset}
      style={{ backgroundImage: "url(" + actualURL + ")" }}
      onClick={() => {
        nav("/asset/" + poster.uid)
      }}
    ></div>
  )
}
