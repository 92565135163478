import { collection, doc, getDoc, getFirestore, onSnapshot, orderBy, query, setDoc } from "firebase/firestore"
import { getDownloadURL, getStorage, ref } from "firebase/storage"
import { useContext, useEffect, useRef, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { Poster } from "schema/dist/src/poster"
import { AppContext, firebaseApp, previewAppWebsite } from "../../App"
import { returnPageKey } from "../../auth"
import styles from "./index.module.css"
import loadingImage from "./../../assets/loadingImage.png"
import { Sticker } from "schema/dist/src/sticker"
import { geohashForLocation } from "geofire-common"

export const stickerParam = "stickerID"
export const Main: React.FC<{}> = ({}): JSX.Element => {
  const [isApple, setIsApple] = useState(true)
  const nav = useNavigate()
  const loc = useLocation()
  let appleClass = styles.AndroidButton
  let androidClass = styles.AndroidButton
  const { user } = useContext(AppContext)
  const db = getFirestore(firebaseApp)
  const [posterList, setPosterList] = useState<Poster[]>([])
  const [selectedPoster, setSelectedPoster] = useState<Poster>()
  const [activateStatus, setActivateStatus] = useState<"Activate" | "Activating" | "Activated">("Activate")
  // const [isFinished, setIsFinished] = useState(false)
  const anchorRef = useRef<HTMLAnchorElement>(null)
  const search = useLocation().search
  const stickerID = new URLSearchParams(search).get(stickerParam) ?? ""

  useEffect(() => {
    if (stickerID == "") return
    anchorRef.current?.scrollIntoView({ behavior: "smooth", block: "start" })
  }, [anchorRef.current])

  if (isApple) {
    appleClass += " " + styles.AndroidButtonSelected
  } else {
    androidClass += " " + styles.AndroidButtonSelected
  }

  useEffect(() => {
    if (user === null) {
      return
    }

    const posterRefParent = doc(db, "userData", user.uid)
    const posterCollection = collection(posterRefParent, "userPosters")
    const posterQuery = query(posterCollection, orderBy("timestamp", "desc"))
    const userPosterDone = onSnapshot(posterQuery, (snap) => {
      const posterItems = snap.docs.map((doc) => {
        return doc.data() as Poster
      })
      setPosterList(posterItems)
    })

    return () => {
      userPosterDone()
    }
  }, [user, db])

  function ActivateSticker() {
    if (selectedPoster == undefined) return
    if (stickerID === "") return

    setActivateStatus("Activating")

    GetSticker(stickerID)
      .then((sticker) => {
        sticker.ownerId = user?.uid ?? ""
        sticker.posterUid = selectedPoster.uid
        return SetSticker(sticker)
      })
      .then((sticker) => {
        console.log(true)
        navigator.geolocation.getCurrentPosition(showPosition)
      })
  }

  function showPosition(position: GeolocationPosition) {
    console.log(position)
    if (selectedPoster == undefined) return
    if (user === null) return
    const hash = geohashForLocation([position.coords.latitude, position.coords.longitude])
    selectedPoster.latitude = position.coords.latitude
    selectedPoster.longitude = position.coords.longitude
    selectedPoster.isPublic = true
    selectedPoster.geoHash = hash
    SetPosterData(selectedPoster, user.uid)
      .then((poster) => {
        setActivateStatus("Activated")
      })
      .catch(() => {})
  }

  return (
    <div className={styles.Root}>
      <HeaderImage />
      <h1>Welcome to the Revivar Family</h1>
      <div className={styles.HappyUnbox} />
      <span className={styles.SubTitle}>
        In order to successfully activate your Revivart please follow the instructions listed below
      </span>
      <br />
      <br />

      <h2 className={styles.SubTitle}>Select your Device Type</h2>
      <div className={styles.AndroidButtonStack}>
        <div
          className={appleClass}
          onClick={() => {
            setIsApple(true)
          }}
        >
          Apple
        </div>
        <div
          className={androidClass}
          onClick={() => {
            setIsApple(false)
          }}
        >
          Android
        </div>
      </div>

      <span className={styles.SubTitle}>
        <b> Online Poster Activation</b>
      </span>
      <span className={styles.Instruction}>
        1. Place the revivar sticker anywhere you like on the revivart. Its better is its visible to others but also works when
        placed behind the poster
      </span>
      <div className={styles.HappyUser} />

      {user == null ? (
        <>
          <span className={styles.Instruction}>2. Login to your account</span>
          <div
            className={androidClass}
            onClick={() => {
              nav(`/signin?${returnPageKey}=${loc.pathname + loc.search}`)
            }}
          >
            Login
          </div>
        </>
      ) : (
        <>
          <span className={styles.Instruction}>
            2. Using your phone, tap on the sticker you want to activate. Then click "activate"
          </span>

          <a ref={anchorRef}></a>

          {stickerID !== "" ? (
            <>
              <br />
              <b> Done: Your Revivart Ready to be activated</b>
              <br />
              <span className={styles.Instruction}>3. Select the poster you want to activate</span>
              <div className={styles.PosterList}>
                {posterList.map((poster) => {
                  return (
                    <GalleryAsset
                      key={poster.uid}
                      poster={poster}
                      onClick={(poster) => {
                        setActivateStatus("Activate")
                        setSelectedPoster(poster)
                      }}
                    />
                  )
                })}
              </div>
            </>
          ) : null}

          <br />

          {selectedPoster !== undefined ? (
            <>
              <span className={styles.Instruction}>
                3. Click on activate. (By clicking activate you are now linking this poster to this sticker)
              </span>
              <div className={styles.AndroidButtonStack}>
                <GalleryAsset poster={selectedPoster} onClick={setSelectedPoster} />
                <br />

                <div className={androidClass} onClick={ActivateSticker}>
                  {activateStatus}
                </div>
              </div>
            </>
          ) : null}

          {activateStatus == "Activated" ? (
            <>
              {isApple ? (
                <>
                  <span className={styles.Instruction}>
                    4. Download the Revivar App from the app store and preview your poster{" "}
                  </span>
                  <div className={styles.AndroidButtonStack}>
                    <div
                      className={styles.DownloadApp}
                      onClick={() => {
                        window.open("https://apps.apple.com/us/app/revivar/id1559129541")
                      }}
                    />
                  </div>
                </>
              ) : (
                <>
                  <span className={styles.Instruction}>4. Preview Poster on Browser Preview </span>
                  <div className={styles.AndroidButtonStack}>
                    <div
                      className={androidClass}
                      onClick={() => {
                        window.open(previewAppWebsite + "/sticker/" + stickerID)
                      }}
                    >
                      Preview in Browser
                    </div>
                  </div>
                </>
              )}
            </>
          ) : null}
        </>
      )}

      {isApple ? (
        <>
          <br />
          <br />
          <br />
          <br />

          <span className={styles.SubTitle}>
            <b> In-App Poster Activation</b>
          </span>

          <span className={styles.Instruction}>1. Download the Revivar App from the app store </span>
          <div className={styles.AndroidButtonStack}>
            <div
              className={styles.DownloadApp}
              onClick={() => {
                window.open("https://apps.apple.com/us/app/revivar/id1559129541")
              }}
            />
          </div>

          <span className={styles.Instruction}>2. Login to your account and go to the your poster</span>
          <span className={styles.Instruction}>
            3. Go to “My Posters”, find this poster and then click “Link sticker to Poster”
          </span>
          <span className={styles.Instruction}>4. Click on “Update Poster Location”</span>
          <span className={styles.Instruction}>
            Now anyone who walks by you poster can see the poster automatically using the app or open it on the browser by taping
            the sticker with the phone
          </span>
        </>
      ) : null}
    </div>
  )
}

export default Main

export const HeaderImage: React.FC<{}> = ({}): JSX.Element => {
  return <div className={styles.HeaderImage}>Activate</div>
}

export const GalleryAsset: React.FC<{ poster: Poster; onClick: (a: Poster) => void }> = ({ poster, onClick }): JSX.Element => {
  const [actualURL, setActualURL] = useState(loadingImage)
  const nav = useNavigate()

  useEffect(() => {
    const storage = getStorage()
    if (poster.imageLDURL.includes("gs://")) {
      const imageStorageRef = ref(storage, poster.imageLDURL)
      getDownloadURL(imageStorageRef).then((downloadURL) => {
        setActualURL(downloadURL)
      })
    } else {
      setActualURL(poster.imageLDURL ?? "")
    }
  }, [poster.imageLDURL])

  return (
    <div
      className={styles.GalleryAsset}
      style={{ backgroundImage: "url(" + actualURL + ")" }}
      onClick={() => {
        onClick(poster)
      }}
    ></div>
  )
}

export const GetSticker = (uuid: string) => {
  const db = getFirestore(firebaseApp)

  return new Promise<Sticker>((resolve, reject) => {
    const docRef = doc(db, "userStickers", uuid)
    getDoc(docRef)
      .then((doc) => {
        if (doc.exists()) {
          const sticker = doc.data() as Sticker
          resolve(sticker)
        } else {
          reject("sticker document not found")
        }
      })
      .catch((error) => {
        reject(error)
        console.log("Error getting Sticker document:", error)
      })
  })
}

export const SetSticker = (sticker: Sticker) => {
  const db = getFirestore(firebaseApp)

  return new Promise<Sticker>((resolve, reject) => {
    const docRef = doc(db, "userStickers", sticker.uid)
    setDoc(docRef, sticker)
      .then((doc) => {
        resolve(sticker)
      })
      .catch((error) => {
        reject(error)
        console.log("Error getting Sticker document:", error)
      })
  })
}

export const SetPosterData = (posterData: Poster, userID: string) => {
  const db = getFirestore(firebaseApp)
  const posterRefParent = doc(db, "userData", userID)

  const posterCollection = collection(posterRefParent, "userPosters")

  return new Promise<Poster>((resolve, reject) => {
    const docRef = doc(posterCollection, posterData.uid)
    setDoc(docRef, posterData)
      .then((doc) => {
        resolve(posterData)
      })
      .catch((error) => {
        reject(error)
        console.log("Error getting Sticker document:", error)
      })
  })
}
