import styles from "./index.module.css"
import { useCallback, useContext, useEffect, useRef, useState } from "react"
import loadingImage from "./../../assets/loadingImage.png"
import { useNavigate, useParams } from "react-router-dom"
import { AppContext, firebaseApp, setAppConfig } from "../../App"
import { Poster } from "schema/dist/src/poster"
import React from "react"
import { collection, deleteDoc, doc, getDocs, getFirestore, onSnapshot, setDoc, Timestamp } from "firebase/firestore"
import { CreateDefaultProduct, CreateProductVariant, Product, ProductVariant } from "schema/dist/src/product"
import { deleteObject, getDownloadURL, getStorage, ref, uploadBytesResumable, UploadTaskSnapshot } from "firebase/storage"
import { Category } from "schema/dist/src/category"
import { GetServerUrl } from "../../create/createRevivart"
import axios from "axios"

type RouteParams = {
  productID: string
}

export const CreateProduct: React.FC<{}> = (): JSX.Element => {
  const { productID } = useParams<RouteParams>()

  const [selectedProduct, setSelectedProduct] = useState<Product>()

  useEffect(() => {
    if (productID === undefined) return
    const db = getFirestore(firebaseApp)

    const orderDetailsRef = doc(db, "shopProducts", productID)

    const done = onSnapshot(orderDetailsRef, (response) => {
      const orderFinal = response.data() as Product
      setSelectedProduct(orderFinal)
    })

    return () => {
      done()
    }
  }, [productID])

  return (
    <>
      <SetProductType productSelected={selectedProduct} />
      <UploadMainContainer productSelected={selectedProduct} />
      <SetNFTLink productSelected={selectedProduct} />
      <SetProductCategory productSelected={selectedProduct} />
      <SelectProductVariant productSelected={selectedProduct} />
      <SelectProductImages productSelected={selectedProduct} />
      <SetProductAction productSelected={selectedProduct} />
    </>
  )
}

export const SetProductType: React.FC<{
  productSelected: Product | undefined
}> = ({ productSelected }): JSX.Element => {
  const OrderTypeListString: String[] = ["SHOP PRODUCT", "AR PRODUCT"]
  const OrderTypeList: (1 | 2)[] = [1, 2]

  if (productSelected === undefined) return <></>

  return (
    <div className={styles.UpdateOrderDetails}>
      <div className={styles.UpdateOrderDetailsList}>
        {OrderTypeList.map((orderType, i) => {
          let className = styles.UpdateOrderStatusButton
          if (orderType === productSelected.typeId) {
            className += " " + styles.UpdateOrderStatusButtonSelected
          }
          return (
            <div
              key={i}
              className={className}
              onClick={() => {
                productSelected.typeId = orderType
                SetUpdateShopProducts(productSelected)
              }}
            >
              {OrderTypeListString[i]}
            </div>
          )
        })}
      </div>
    </div>
  )
}

export const SetNFTLink: React.FC<{
  productSelected: Product | undefined
}> = ({ productSelected }): JSX.Element => {
  const [title, setTitle] = useState(productSelected?.title ?? "")
  const [description, setDescription] = useState(productSelected?.productDescription ?? "")
  const [studioID, setStudioID] = useState(productSelected?.studioID ?? "")

  const [link, setLink] = useState(productSelected?.nftLink ?? "")

  useEffect(() => {
    if (productSelected === undefined) return
    setLink(productSelected.nftLink)
    setDescription(productSelected.productDescription)
    setTitle(productSelected.title)
    setStudioID(productSelected.studioID ?? "")
  }, [productSelected])

  if (productSelected === undefined) return <></>

  return (
    <div className={styles.UpdateOrderDetails}>
      <div className={styles.UpdateOrderDetailsList}>
        <input
          className={styles.UpdateOrderStatusButton}
          onInput={(e) => {
            setTitle(e.currentTarget.value)
          }}
          value={title}
        ></input>
        <div
          className={styles.UpdateOrderStatusButton}
          onClick={() => {
            productSelected.title = title
            SetUpdateShopProducts(productSelected)
          }}
        >
          Update Title
        </div>
      </div>
      <div className={styles.UpdateOrderDetailsList}>
        <input
          className={styles.UpdateOrderStatusButton}
          onInput={(e) => {
            setDescription(e.currentTarget.value)
          }}
          value={description}
        ></input>
        <div
          className={styles.UpdateOrderStatusButton}
          onClick={() => {
            productSelected.productDescription = description
            SetUpdateShopProducts(productSelected)
          }}
        >
          Update Desc
        </div>
      </div>
      <div className={styles.UpdateOrderDetailsList}>
        <input
          className={styles.UpdateOrderStatusButton}
          onInput={(e) => {
            setLink(e.currentTarget.value)
          }}
          value={link}
        ></input>
        <div
          className={styles.UpdateOrderStatusButton}
          onClick={() => {
            productSelected.nftLink = link
            SetUpdateShopProducts(productSelected)
          }}
        >
          Update NFT
        </div>
      </div>
      <div className={styles.UpdateOrderDetailsList}>
        <input
          className={styles.UpdateOrderStatusButton}
          onInput={(e) => {
            setStudioID(e.currentTarget.value)
          }}
          value={studioID}
        ></input>
        <div
          className={styles.UpdateOrderStatusButton}
          onClick={() => {
            productSelected.studioID = studioID
            SetUpdateShopProducts(productSelected)
          }}
        >
          set studio
        </div>
      </div>
    </div>
  )
}

export const SetProductCategory: React.FC<{
  productSelected: Product | undefined
}> = ({ productSelected }): JSX.Element => {
  const [categoryList, setCategoryList] = useState<Category[]>([])

  const db = getFirestore(firebaseApp)

  useEffect(() => {
    if (productSelected === undefined) return

    const orderDetailsRef = collection(db, "shopCategories")

    getDocs(orderDetailsRef)
      .then((response) => {
        const categoryList = response.docs.map((doc) => {
          return doc.data() as Category
        })
        setCategoryList(categoryList)
      })

      .catch((err) => {
        console.error(err)
      })
  }, [productSelected, db])

  if (productSelected === undefined) return <></>

  return (
    <div className={styles.UpdateOrderDetails}>
      CATEGORIES
      <div className={styles.UpdateOrderDetailsList}>
        {categoryList.map((category) => {
          let className = styles.UpdateOrderStatusButton

          if (category.uid === productSelected.category) {
            className += " " + styles.UpdateOrderStatusButtonSelected
          }
          return (
            <div
              key={category.uid}
              className={className}
              onClick={() => {
                productSelected.category = category.uid
                SetUpdateShopProducts(productSelected)
              }}
            >
              {category.title}
            </div>
          )
        })}
      </div>
    </div>
  )
}

export const UploadMainContainer: React.FC<{
  productSelected: Product | undefined
}> = ({ productSelected }): JSX.Element => {
  return (
    <div className={styles.UploadMainContainer}>
      <UploadMainSection productSelected={productSelected} />
      <UploadMainSection productSelected={productSelected} isLD />
      <UploadMainSection productSelected={productSelected} isVideo />
    </div>
  )
}

export const UploadMainSection: React.FC<{
  productSelected: Product | undefined
  isVideo?: boolean
  isLD?: boolean
}> = ({ productSelected, isVideo = false, isLD = false }): JSX.Element => {
  const [resourceURL, setResourceURL] = useState(loadingImage)
  const [resourceBlob, setResourceBlob] = useState<File | Blob>()
  const [uploadTitle, setUploadTitle] = useState(isLD ? "UPLOAD LD" : "UPLOAD HD")

  const hiddenFileInput = useRef<HTMLInputElement>(null)

  function handleResourceClicked(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.files !== null) {
      const file = e.target.files[0]
      setResourceBlob(file)
      const imageURL = URL.createObjectURL(file)
      setResourceURL(imageURL)
    }
  }

  function uploadResource() {
    if (resourceBlob === undefined || productSelected === undefined) {
      setUploadTitle("SELECT FILE")
      return
    }

    const fileName = isVideo ? "ARvideo.mp4" : isLD ? "LDARimage.jpg" : "HDARimage.jpg"

    uploadFile(
      fileName,
      `/productImages/${productSelected?.uid}/`,
      resourceBlob,
      (a: number) => {
        setUploadTitle(a.toPrecision(2) + "%")
      },
      (a, ref) => {
        if (isVideo) {
          productSelected.videoURL = a
        } else {
          if (isLD) {
            productSelected.imageLDURL = a
          } else {
            productSelected.imageHDURL = a
          }
        }
        console.log(productSelected)
        SetUpdateShopProducts(productSelected)
      }
    )
      .then((ref) => {})
      .catch(() => {})
      .finally(() => {
        setUploadTitle("UPLOAD")
      })
  }

  const GetVideoData = useCallback(
    (productSelected: Product) => {
      const storage = getStorage()
      const linkRef = isVideo ? productSelected.videoURL : isLD ? productSelected.imageLDURL : productSelected.imageHDURL

      if (linkRef === "") return
      getDownloadURL(ref(storage, linkRef))
        .then((url) => {
          setResourceURL(url)
        })
        .catch((error) => {
          console.error(error)
        })
    },
    [isVideo]
  )

  useEffect(() => {
    if (productSelected === undefined) return
    GetVideoData(productSelected)
  }, [productSelected, GetVideoData])

  if (productSelected === undefined) return <></>

  return (
    <div className={styles.UploadMainSection}>
      <input
        style={{ display: "none" }}
        ref={hiddenFileInput}
        type="file"
        accept={isVideo ? ".mp4" : ".png, .jpeg, .png"}
        onChange={handleResourceClicked}
      />
      <div
        className={styles.UploadMainSectionTop}
        onClick={() => {
          hiddenFileInput.current?.click()
        }}
      >
        {!isVideo ? <img src={resourceURL} alt={"img"}></img> : <video src={resourceURL} loop autoPlay muted controls></video>}
      </div>
      <div className={styles.UploadMainSectionBottom}>
        <div className={styles.UpdateOrderDetails}>
          <div className={styles.UpdateOrderDetailsList}>
            <div className={styles.UpdateOrderStatusButton} onClick={uploadResource}>
              {uploadTitle}: {((resourceBlob?.size ?? 0) / (1024 * 1024)).toFixed(0)}MB
            </div>
            <div
              className={styles.UpdateOrderStatusButton}
              onClick={() => {
                GetVideoData(productSelected)
              }}
            >
              RESET
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export const SelectProductVariant: React.FC<{
  productSelected: Product | undefined
  isVideo?: boolean
}> = ({ productSelected, isVideo = false }): JSX.Element => {
  const [variantList, setVariantList] = useState<ProductVariant[]>(productSelected?.productVariantList ?? [])
  useEffect(() => {
    if (productSelected === undefined) return
    setVariantList(productSelected?.productVariantList)
  }, [productSelected])

  if (productSelected === undefined) return <></>

  return (
    <div className={styles.UpdateOrderDetails}>
      VARIANT
      <div className={styles.SelectProductVariant}>
        {variantList.map((variant, i) => {
          return (
            <SelectProductVariantView
              key={i}
              variant={variant}
              onVariantUpdate={(newVar) => {
                variantList[i] = newVar
                setVariantList([...variantList])
                console.log(variantList)
              }}
              onVariantDelete={() => {
                var filtered = variantList.filter(function (value) {
                  return value !== variant
                })
                console.log(filtered)
                setVariantList([...filtered])
              }}
            />
          )
        })}
      </div>
      <div className={styles.UpdateOrderDetailsList}>
        <div
          className={styles.UpdateOrderStatusButton}
          onClick={() => {
            productSelected.productVariantList = variantList
            SetUpdateShopProducts(productSelected)
          }}
        >
          UPDATE
        </div>
        <div
          className={styles.UpdateOrderStatusButton}
          onClick={() => {
            setVariantList(productSelected.productVariantList)
          }}
        >
          RESET
        </div>
        <div
          className={styles.UpdateOrderStatusButton}
          onClick={() => {
            GetDefaultProductVariants("defaults")
              .then((variants) => {
                setVariantList(variants)
                console.log(variants)
              })
              .catch((error) => {
                console.error(error)
              })
          }}
        >
          DEFAULTS
        </div>
        <div
          className={styles.UpdateOrderStatusButton}
          onClick={() => {
            const newList = [...variantList]
            newList.push(CreateProductVariant(""))
            setVariantList(newList)
          }}
        >
          ADD
        </div>
      </div>
    </div>
  )
}

export const SelectProductVariantView: React.FC<{
  variant: ProductVariant
  onVariantUpdate: (variant: ProductVariant) => void
  onVariantDelete: () => void
}> = ({ variant, onVariantUpdate, onVariantDelete }): JSX.Element => {
  return (
    <div className={styles.SelectProductVariantView}>
      <div className={styles.SelectProductVariantViewItem}>
        size
        <input
          value={variant.size}
          onInput={(e) => {
            variant.size = e.currentTarget.value
            onVariantUpdate(variant)
          }}
        />
      </div>
      <div className={styles.SelectProductVariantViewItem}>
        price
        <input
          value={variant.price}
          onInput={(e) => {
            const numString = parseFloat(e.currentTarget.value)
            if (isNaN(numString)) return
            variant.price = numString
            onVariantUpdate(variant)
          }}
        />
      </div>
      <div className={styles.SelectProductVariantViewItem}>
        count
        <input
          value={variant.count}
          onInput={(e) => {
            const numString = parseFloat(e.currentTarget.value)
            if (isNaN(numString)) {
              return
            }
            variant.count = numString
            onVariantUpdate(variant)
          }}
        />
      </div>
      <div className={styles.SelectProductVariantViewItem}>
        PrintFull ID
        <input
          value={variant.printfulVariantID}
          onInput={(e) => {
            const numString = parseFloat(e.currentTarget.value)
            if (isNaN(numString)) return
            variant.printfulVariantID = numString
            onVariantUpdate(variant)
          }}
        />
      </div>
      <div className={styles.SelectProductVariantViewItem}>
        P.WarehouseID
        <input
          value={variant.printfulWarehouseID}
          onInput={(e) => {
            const numString = parseFloat(e.currentTarget.value)
            if (isNaN(numString)) return
            variant.printfulWarehouseID = numString
            onVariantUpdate(variant)
          }}
        />
      </div>
      <div className={styles.UpdateOrderStatusButton} onClick={onVariantDelete}>
        DELETE
      </div>
    </div>
  )
}

export const SetProductAction: React.FC<{
  productSelected: Product | undefined
}> = ({ productSelected }): JSX.Element => {
  const nav = useNavigate()
  const { userToken, user } = useContext(AppContext)

  if (productSelected === undefined) return <></>

  let classNameEnabled = styles.UpdateOrderStatusButton
  let classNameDisEnabled = classNameEnabled + " " + styles.UpdateOrderStatusButtonSelected

  return (
    <div className={styles.UpdateOrderDetails}>
      <div className={styles.UpdateOrderDetailsList}>
        <div
          className={!productSelected.isActive ? classNameEnabled : classNameDisEnabled}
          onClick={() => {
            productSelected.isActive = true
            SetUpdateShopProducts(productSelected)
          }}
        >
          ENABLED
        </div>
        <div
          className={productSelected.isActive ? classNameEnabled : classNameDisEnabled}
          onClick={() => {
            productSelected.isActive = false
            SetUpdateShopProducts(productSelected)
          }}
        >
          DISABLED
        </div>
        <div
          className={classNameEnabled}
          onClick={() => {
            nav("/shop/product/" + productSelected.uid)
          }}
        >
          PREVIEW
        </div>
        <div
          className={classNameEnabled}
          onClick={() => {
            if (productSelected.posterUid !== "") {
              console.log("Poster already Exist")
              return
            }
            console.log("not needed rn")
            return
            // PasteurizeProduct(productSelected, userToken, user?.uid ?? "")
          }}
        >
          POSTERIZE
        </div>
        <div
          className={classNameEnabled}
          onClick={() => {
            DeleteUpdateShopCategory(productSelected)
              .then(() => {
                nav("../")
              })
              .catch(() => {})
              .finally(() => {})
          }}
        >
          DELETE
        </div>
        {productSelected.posterUid !== "" ? (
          <div
            className={classNameEnabled}
            onClick={() => {
              nav("/asset/" + productSelected.posterUid)
            }}
          >
            VIEW POSTER
          </div>
        ) : null}
      </div>
    </div>
  )
}

function PasteurizeProduct(productSelected: Product, token: string, userID: string) {
  const posturizeEndPoint = "/api/api/generate/poster"
  let posterAPI = GetServerUrl(posturizeEndPoint)
  const endPoint = new URL(posterAPI)
  endPoint.searchParams.append("productID", productSelected.uid)
  endPoint.searchParams.append("customerID", userID ?? "")
  endPoint.searchParams.append("sameID", "true")

  return axios
    .get(endPoint.toString(), {
      headers: { Authorization: "Bearer " + token },
    })
    .then((response) => {
      const posterData = response.data as Poster

      productSelected.posterUid = posterData.uid
      return SetUpdateShopProducts(productSelected)
    })
    .then((response) => {
      console.log("DONE PASTEURIZING")
    })
    .catch((error) => {
      console.error(error)
    })
}

export const SelectProductImages: React.FC<{
  productSelected: Product | undefined
  isVideo?: boolean
}> = ({ productSelected, isVideo = false }): JSX.Element => {
  const [imagesList, setImagesList] = useState<string[]>(productSelected?.productImagesURL ?? [])

  const [stateTag, setStateTag] = useState("ADD")
  const hiddenFileInput = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (productSelected === undefined) return
    setImagesList(productSelected?.productImagesURL)
  }, [productSelected])

  function handleResourceClicked(e: React.ChangeEvent<HTMLInputElement>) {
    if (productSelected === undefined) return

    if (e.target.files !== null) {
      const file = e.target.files[0]
      uploadFile(
        "updatedImage.jpg" + imagesList.length + 1,
        "/productImages/" + productSelected.uid + "/",
        file,
        (a) => {
          setStateTag(a.toPrecision(3) + "%")
        },
        (a, ref) => {
          const newList = [...productSelected.productImagesURL]
          newList.push(a)
          productSelected.productImagesURL = newList
          SetUpdateShopProducts(productSelected)
        }
      )
        .catch((err) => {
          console.error(err)
        })
        .finally(() => {
          setStateTag("ADD")
        })
    }
  }

  if (productSelected === undefined) return <></>

  return (
    <div className={styles.UpdateOrderDetails}>
      PRODUCT IMAGES
      <input
        style={{ display: "none" }}
        ref={hiddenFileInput}
        type="file"
        accept={".png, .jpeg, .png"}
        onChange={handleResourceClicked}
      />
      <div className={styles.SelectProductVariant}>
        {imagesList.map((imageURL, i) => {
          return (
            <SelectProductImageItem
              key={imageURL}
              pictureURL={imageURL}
              onDeleteClicked={() => {
                var filtered = imagesList.filter(function (value) {
                  return value !== imageURL
                })
                productSelected.productImagesURL = filtered
                SetUpdateShopProducts(productSelected)
                  .then(() => {
                    const storage = getStorage()
                    return deleteObject(ref(storage, imageURL))
                  })
                  .then(() => {
                    console.log("DONE")
                  })
                  .catch(() => {
                    setStateTag("ADD")
                  })
              }}
            />
          )
        })}
      </div>
      <div className={styles.UpdateOrderDetailsList}>
        <div
          className={styles.UpdateOrderStatusButton}
          onClick={() => {
            hiddenFileInput.current?.click()
          }}
        >
          {stateTag}
        </div>
      </div>
    </div>
  )
}

export const SelectProductImageItem: React.FC<{
  pictureURL: string
  onDeleteClicked: () => void
}> = ({ pictureURL, onDeleteClicked }): JSX.Element => {
  const [imagesURL, setImagesURL] = useState(loadingImage)

  useEffect(() => {
    const storage = getStorage()
    getDownloadURL(ref(storage, pictureURL))
      .then((url) => {
        setImagesURL(url)
      })
      .catch((error) => {
        console.error(error)
      })
  }, [pictureURL])

  return (
    <div className={styles.SelectProductVariantView}>
      <img className={styles.SelectProductImageItem} src={imagesURL} alt={"img"}></img>
      <div className={styles.SelectProductVariantViewItem}>
        <div className={styles.UpdateOrderStatusButton} onClick={onDeleteClicked}>
          DELETE
        </div>
      </div>
    </div>
  )
}

/**
 *
 * @param fileName the name of the file to write to
 * @param location the path location. should always start with a slash and end with a slash
 * @example /productImages/category/ not /productImages
 * @param file the file to write to
 * @param onUpdate this function will be called on change of state of upload
 * @param onCompleted
 * @returns
 */
export function uploadFile(
  fileName: string,
  location: string,
  file: File | Blob,
  onUpdate: (a: number) => void,
  onCompleted: (a: string, ref: UploadTaskSnapshot) => void
) {
  const Video = `${location}${fileName}`

  let VideoDestination = `gs://${setAppConfig.storageBucket}${Video}`

  const storage = getStorage()

  const VideoRequest = uploadBytesResumable(ref(storage, VideoDestination), file)

  let VideoRequestProgress = 0
  VideoRequest.on("state_changed", (snapshot) => {
    VideoRequestProgress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
    onUpdate(VideoRequestProgress)
  })

  return new Promise<{ dest: string; ref: UploadTaskSnapshot }>((resolve, reject) => {
    return VideoRequest.then((ref) => {
      onCompleted(VideoDestination, ref)
      resolve({ dest: VideoDestination, ref: ref })
    }).catch((err) => {
      reject(err)
    })
  })
}

export function CreateNewShopProducts() {
  const db = getFirestore(firebaseApp)
  const ref = doc(collection(db, "shopProducts"))

  const newProduct = CreateDefaultProduct(ref.id, Timestamp.now())

  return SetUpdateShopProducts(newProduct).then((result) => {
    return ref.id
  })
}

function SetUpdateShopProducts(productSelected: Product) {
  const db = getFirestore(firebaseApp)
  const orderDetailsRef = doc(db, "shopProducts", productSelected.uid)

  return setDoc(orderDetailsRef, productSelected)
}

export function GetDefaultProductVariants(type: "defaults" | "polaroids") {
  const db = getFirestore(firebaseApp)
  const orderDetailsRef = collection(doc(db, "appData", "posterVariants"), type)

  return getDocs(orderDetailsRef).then((response) => {
    return response.docs.map((doc) => {
      const data = doc.data() as ProductVariant
      data.uid = doc.id
      return data
    })
  })
}

export function DeleteUpdateShopCategory(product: Product) {
  const db = getFirestore(firebaseApp)
  const docRef = doc(db, "shopProducts", product.uid)

  return deleteDoc(docRef).then(() => {
    const storage = getStorage()

    return Promise.allSettled([
      deleteObject(ref(storage, product.videoURL)),
      deleteObject(ref(storage, product.imageHDURL)),
      deleteObject(ref(storage, product.imageLDURL)),
    ])
  })
}
