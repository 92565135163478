import styles from "./styles/home.module.css"

export const NotFound: React.FC<{}> = (): JSX.Element => {
  return (
    <div className={styles.root}>
      <div className={styles.LostComponent}>
        404
        <div className={styles.LostComponentSubTitle}>Page not found</div>
        <div className={styles.LostComponentText}>
          The page you are looking for might have been removed or is temporarily
          unavailable
        </div>
      </div>
    </div>
  )
}
export default NotFound
