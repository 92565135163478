import styles from "./index.module.css"
import { useRef, useState } from "react"
import MixedWall from "../../assets/video/mixwall.mp4"
import MixedWallHold from "../../assets/video/mixwall.jpg"

import LOVEFRAME from "../../assets/video/LOVEFRAME.mp4"
import showTell from "../../assets/video/showTell.mp4"
import videoFrame from "../../assets/video/FRAME_1.mp4"
import polaroid from "../../assets/video/polaroid.mp4"
import invite from "../../assets/video/invite.mp4"
import sports from "../../assets/video/sports.mp4"
import studio from "../../assets/video/studio.mp4"
import { useNavigate } from "react-router-dom"

export const Main: React.FC<{}> = ({}): JSX.Element => {
  const cursorRef = useRef<HTMLDivElement>(null)
  const nav = useNavigate()

  return (
    <div
      className={styles.root}
      onMouseMove={(e) => {
        if (cursorRef.current === null) {
          return
        }

        const { clientX, clientY } = e

        cursorRef.current.style.left = clientX + "px"
        cursorRef.current.style.top = clientY + "px"
      }}
    >
      <div className={styles.cursor} ref={cursorRef}></div>

      <IntroVideo placeholder={MixedWallHold} videoURL={MixedWall} />

      <LargeMediumText>
        A place to create and buy premium moving pictures. Revivar allows you pictures to life using Augmented Reality
      </LargeMediumText>
      <FrameExplained />
      <SimpleCreation />
      <DemoVideo />
      <LargeLargeText>
        Get it shipped <br />
        or <br />
        Print it yourself <br />
      </LargeLargeText>
      <LargeSmallText>
        We can print it for you or you can revive an existing printed art work. By simply uploading a video and selecting single
        image you can create your live art that anyone can see.
      </LargeSmallText>
      <ExamplesLarge videoURL={videoFrame}>
        Posters
        <div
          className={styles.ViewMoreButton}
          onClick={() => {
            nav("./studio/poster")
          }}
        >
          Create
        </div>
      </ExamplesLarge>
      <ExamplesLarge videoURL={polaroid} right>
        Polaroids
        <div
          className={styles.ViewMoreButton}
          onClick={() => {
            nav("./studio/polaroid")
          }}
        >
          Create
        </div>
      </ExamplesLarge>
      <ExamplesLarge videoURL={invite}>Invitations</ExamplesLarge>
      <ExamplesLarge videoURL={sports} right>
        Sports Cards
      </ExamplesLarge>
      <IconicText>
        Iconic Moments <br /> Unforgettable Gifts
      </IconicText>
      <ShopPosterButtonLarge />
      <ReferenceVideo />
      <AfricaContent />
    </div>
  )
}

export default Main

interface IntroVideoProps {
  placeholder: string
  videoURL: string
}

const IntroVideo: React.FC<IntroVideoProps> = ({ placeholder, videoURL }): JSX.Element => {
  const [hidden, setHidden] = useState(true)
  return (
    <div className={styles.IntroVideoContainer} style={{ backgroundImage: "url(" + placeholder + ")" }}>
      <IntroVideoContent />
      <video
        loop
        autoPlay
        muted
        playsInline
        src={videoURL}
        onCanPlay={() => {
          setHidden(false)
        }}
        hidden={hidden}
      ></video>
    </div>
  )
}

export const IntroVideoContent: React.FC<{}> = (): JSX.Element => {
  const nav = useNavigate()
  return (
    <div className={styles.IntroVideoContainerMain}>
      <div className={styles.IntroVideoContainerMainTop}>
        <div className={styles.LogoImage}></div>
        <div className={styles.MainMenu}>
          <span
            onClick={() => {
              nav("/shop")
            }}
          >
            SHOP
          </span>
          <span
            onClick={() => {
              window.open("https://apps.apple.com/us/app/revivar/id1559129541")
            }}
          >
            DOWNLOAD
          </span>
        </div>
      </div>
      <div className={styles.IntroVideoContainerMainMid}>
        <div className={styles.LogoText}></div>
        <span
          onClick={() => {
            window.scrollTo({ top: window.innerHeight, behavior: "smooth" })
          }}
        >
          {" "}
          Learn More
        </span>
      </div>
      <div className={styles.IntroVideoContainerMainBottom}>
        <div className={styles.TobennaWesLogoImage}></div>
      </div>
    </div>
  )
}

export const LargeSmallText: React.FC<{}> = ({ children }): JSX.Element => {
  return <div className={styles.LargeSmallText}>{children}</div>
}

export const LargeMediumText: React.FC<{}> = ({ children }): JSX.Element => {
  return <div className={styles.LargeMediumText}>{children}</div>
}

export const FrameExplained: React.FC<{}> = (): JSX.Element => {
  return (
    <div className={styles.FrameExplained}>
      <video loop autoPlay muted playsInline src={LOVEFRAME}></video>
      <h1>Moments Stored in a Frame </h1>

      <span>
        Until now AR for Art has not lived up to its hype. Revivar is changing that. Now everyone can create pictures that look
        magical.
      </span>
    </div>
  )
}

export const SimpleCreation: React.FC<{}> = (): JSX.Element => {
  return <div className={styles.SimpleCreation}></div>
}

export const DemoVideo: React.FC<{}> = (): JSX.Element => {
  return (
    <div className={styles.DemoVideo}>
      <span>
        The<h1> Future of Printed Art </h1>
        Revivar using Image recognition, geocaching and NFCs to know what videos are for what prints. Allowing it to works
        anywhere in the world!
      </span>
      <video loop autoPlay muted playsInline controls src={showTell}></video>
    </div>
  )
}

export const LargeLargeText: React.FC<{}> = ({ children }): JSX.Element => {
  return <div className={styles.LargeLargeText}>{children}</div>
}

interface ExamplesLargeProps {
  right?: boolean
  videoURL: string
}
export const ExamplesLarge: React.FC<ExamplesLargeProps> = ({ right = false, videoURL, children }): JSX.Element => {
  let className = styles.ExamplesLarge

  if (right) {
    className += " " + styles.ExamplesLargeRight
  }
  return (
    <div className={className}>
      <video loop autoPlay muted playsInline src={videoURL}></video>
      <span>{children}</span>
    </div>
  )
}

export const IconicText: React.FC<{}> = ({ children }): JSX.Element => {
  return <div className={styles.IconicText}>{children}</div>
}

export const ShopPosterButtonLarge: React.FC<{}> = (): JSX.Element => {
  const nav = useNavigate()
  return (
    <div
      className={styles.ShopPosterButtonLarge}
      onClick={() => {
        nav("./studio/poster")
      }}
    >
      Create Now
    </div>
  )
}

export const ReferenceVideo: React.FC<{}> = (): JSX.Element => {
  return (
    <div className={styles.ReferenceVideo}>
      <HistoryContent />
      <video loop autoPlay muted playsInline src={studio}></video>
    </div>
  )
}

export const HistoryContent: React.FC<{}> = (): JSX.Element => {
  let nav = useNavigate()
  return (
    <div className={styles.HistoryContentMain}>
      <div className={styles.TobennaWesLogoImage}></div>
      <b> A TOBENNAWES PRODUCT</b>
      <span>
        In 2019 Tobennawes decided to make an app to all people connect with his posters more but the soon realized that everyone
        should be able to connect with their prints in such way. In 2020 he revivar was released.{" "}
      </span>
      <div
        className={styles.ShopPosterButton}
        onClick={() => {
          nav("/shop/tobennawes")
        }}
      >
        Shop Now
      </div>
    </div>
  )
}

export const AfricaContent: React.FC<{}> = (): JSX.Element => {
  return (
    <div className={styles.AfricaContent}>
      African owned company
      <div className={styles.AfricaContentIcon}></div>
    </div>
  )
}
