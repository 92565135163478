import React, { useContext, useEffect, useRef, useState } from "react"
import styles from "./index.module.css"
import { getDoc } from "firebase/firestore"
import { getFirestore, doc, collection } from "firebase/firestore"
import { getStorage, ref, getBlob } from "firebase/storage"
import { AppContext, firebaseApp } from "../../App"

import { Product, ProductVariant } from "schema/dist/src/product"
import { useNavigate, useParams } from "react-router-dom"
import ImagePlaceHolder from "../../assets/POL2.jpg"
import {
  AddSessionToCart,
  CreateStudioSession,
  FrequentList,
  IncludedList,
  IntroBody,
  IntroVideo,
  ValidateAssetSize,
  VariantContainer,
} from "../shared/shared"
import { Polaroid3D } from "./polaroid3D"
import { PosterData, UploadToAssetLocation } from "../shared/schema"
import { StudioSession, StudioSessionAssets } from "schema/dist/src/studio"

import { ValidateUserToAnon } from "../../auth"

const studioID = "polaroid"
export const Main: React.FC<{}> = (): JSX.Element => {
  const { user } = useContext(AppContext)
  const [studioProduct, setStudioProduct] = useState<Product>()
  const [selectedVariant, setSelectedVariant] = useState<ProductVariant>()
  const [session, setSession] = useState<StudioSession>()
  const navigate = useNavigate()
  const { sessionID } = useParams<{ sessionID: string }>()

  // ON PAGE LOAD  //
  useEffect(() => {
    const db = getFirestore(firebaseApp)
    if (sessionID !== undefined) {
      const sessionCollRef = collection(db, "studioSessions")
      let sessionRef = doc(sessionCollRef, sessionID)
      getDoc(sessionRef)
        .then((doc) => {
          if (!doc.exists()) {
            return
          }
          const newSession = doc.data() as StudioSession
          setSession(newSession)
        })
        .catch((err) => {})
    } else {
      setSession(undefined)
    }
  }, [sessionID])

  useEffect(() => {
    const db = getFirestore(firebaseApp)

    if (selectedVariant === undefined || session === undefined) return

    const sessionData: StudioSessionAssets[] = []

    if (session.sessionData.length === selectedVariant.count && session.productVariant.count === selectedVariant.count) return

    for (let index = 0; index < (selectedVariant.count ?? 0); index++) {
      const posterRef = doc(collection(db, "userPosters"))
      sessionData.push({
        videoURL: session.sessionData[index]?.videoURL ?? "",
        imageURL: session.sessionData[index]?.imageURL ?? "",
        posterUID: session.sessionData[index]?.posterUID ?? posterRef.id,
      })
    }

    session.sessionData = sessionData
    session.productVariant = selectedVariant
    setSession(JSON.parse(JSON.stringify(session)))
  }, [selectedVariant])

  // IF NO ID IN PARAM WE MAKE ONEs
  return (
    <div className={styles.root}>
      <div className={styles.VideoTop}>
        <IntroVideo videoURL={""} backdrop={ImagePlaceHolder}>
          <IntroBody
            title="Create your Revivar Polaroid"
            subTitle="Custom Live Polaroid Prints with an AR Video"
            session={session}
            onClick={() => {
              if (selectedVariant === undefined) return
              CreateStudioSession(selectedVariant, user?.uid ?? "", "polaroid")
                .then((session) => {
                  console.log("done")
                  setSession(session)
                  navigate({
                    pathname: "/studio/polaroid/" + session.uid,
                  })
                  window.scrollTo({ top: window.innerHeight, behavior: "smooth" })
                })
                .catch((err) => {})
            }}
          />
        </IntroVideo>
      </div>

      <VariantContainer
        setProduct={setStudioProduct}
        product={studioProduct}
        setSelectedVariant={setSelectedVariant}
        selectedVariant={selectedVariant}
        studioID={studioID}
        studioSession={session}
      />
      <PolaroidPreview session={session} setSession={setSession} product={studioProduct} sessionID={sessionID} />

      <IncludedList />
      <br />
      <br />
      <FrequentList />
    </div>
  )
}
export default Main

interface PolaroidPreviewProps {
  session: StudioSession | undefined
  setSession: (session: StudioSession) => void
  product: Product | undefined
  sessionID: string | undefined
}

export const PolaroidPreview: React.FC<PolaroidPreviewProps> = ({ session, product, sessionID }): JSX.Element => {
  const [polaroidData, setPolaroidData] = useState<PosterData[]>([])
  const [playVideo, setPlayVideo] = useState(true)
  const [showVideo, setShowVideo] = useState(true)
  const [selectedPolaroid, setSelectedPolaroid] = useState(0)
  const [previewTime, setPreviewTime] = useState<number>()
  const [creationStep, setCreationStep] = useState(0)
  const [isUploading, setIsUploading] = useState(false)
  const [uploadingText, setUploadingText] = useState("")

  const nav = useNavigate()
  const { user } = useContext(AppContext)

  useEffect(() => {
    if (session === undefined) return
    const data = session?.sessionData.map((sessionData): PosterData => {
      return {
        videoURL: sessionData.videoURL,
        imageURL: sessionData.imageURL,
        uid: sessionData.posterUID,
      }
    })
    setSelectedPolaroid(0)
    setPolaroidData(data)
    setPlayVideo(false)
  }, [session])

  useEffect(() => {
    setCreationStep(0)
    setPlayVideo(false)
  }, [selectedPolaroid])

  if (session === undefined || polaroidData.length === 0) return <></>

  let showAddToCart = true
  session.sessionData.forEach((sesh) => {
    if (sesh.videoURL === "" || sesh.imageURL === "" || sesh.posterUID === "" || isUploading) {
      showAddToCart = false
    }
  })

  let playButtonClass = styles.playButton
  if (playVideo) {
    playButtonClass += " " + styles.pausePlayButton
  }

  if (!session?.editable) {
    return (
      <div className={styles.PolaroidPreview}>
        <Polaroid3D
          polaroids={polaroidData}
          onSelected={setSelectedPolaroid}
          polaroidsInView={selectedPolaroid}
          playVideo={playVideo}
          previewTime={previewTime}
          showVideo={showVideo}
        />

        <div
          className={playButtonClass}
          onClick={() => {
            const newState = !playVideo
            setPlayVideo(newState)
            setShowVideo(newState)
          }}
        />

        <div className={styles.ControlsStacker}>
          <CreationControllerStart
            onClick={() => {
              nav("/cart")
            }}
            isHidden={!showAddToCart}
            text={"Back to Cart"}
          />
        </div>
      </div>
    )
  }

  return (
    <div className={styles.PolaroidPreview}>
      <Polaroid3D
        polaroids={polaroidData}
        onSelected={setSelectedPolaroid}
        polaroidsInView={selectedPolaroid}
        playVideo={playVideo}
        previewTime={previewTime}
        showVideo={showVideo}
      />
      {creationStep === 0 ? null : (
        <div
          className={playButtonClass}
          onClick={() => {
            const newState = !playVideo
            setPlayVideo(newState)
            setShowVideo(newState)
          }}
        />
      )}

      {uploadingText}

      <div className={styles.ControlsStacker}>
        <UploadVideo
          onFileSelected={(a: File) => {
            const valid = ValidateAssetSize(a)

            if (!valid.valid) {
              setUploadingText(valid.reason)
              return
            }
            setIsUploading(true)
            UploadToAssetLocation(
              session.sessionData[selectedPolaroid],
              session.sessionData[selectedPolaroid].posterUID + "video",
              session,
              a,
              "video",
              (a) => {
                setUploadingText(`Uploading ${a.toFixed(1)}%`)
              }
            )
              .then((ref) => {
                polaroidData[selectedPolaroid].videoURL = URL.createObjectURL(a)
                setPolaroidData([...polaroidData])
                setCreationStep(1)
                console.log("Done well")
              })
              .catch(() => {
                setUploadingText("Error uploading File")
              })
              .finally(() => {
                setIsUploading(false)
                setUploadingText("")
              })
          }}
          isHidden={creationStep !== 0}
          showNext={(polaroidData[selectedPolaroid]?.videoURL ?? "") !== ""}
          showBack={selectedPolaroid !== 0}
          onNexClicked={(e) => {
            setCreationStep(e ? 1 : 0)
          }}
          disabled={isUploading}
        />
        <UploadPicture
          videoUrl={polaroidData[selectedPolaroid]?.videoURL ?? ""}
          onFileSelected={(a: File | Blob) => {
            setIsUploading(true)
            UploadToAssetLocation(
              session.sessionData[selectedPolaroid],
              session.sessionData[selectedPolaroid].posterUID + "image",
              session,
              a,
              "image",
              (a) => {
                setUploadingText(`Uploading ${a.toFixed(1)}%`)
              }
            )
              .then((ref) => {
                polaroidData[selectedPolaroid].imageURL = URL.createObjectURL(a)
                setPolaroidData([...polaroidData])
                setShowVideo(false)

                setCreationStep(2)
              })
              .catch(() => {
                setUploadingText("Error uploading File")
              })
              .finally(() => {
                setIsUploading(false)
                setUploadingText("")
              })
          }}
          onSlide={(e) => {
            setShowVideo(true)
            setPreviewTime(e)
          }}
          isHidden={creationStep !== 1}
          showNext={(polaroidData[selectedPolaroid]?.imageURL ?? "") !== ""}
          showBack={(polaroidData[selectedPolaroid]?.imageURL ?? "") !== ""}
          onNexClicked={(e) => {
            setCreationStep(e ? 2 : 0)
          }}
          disabled={isUploading}
          fileRatio={0.8}
        />

        {creationStep === 2 ? (
          <div className={styles.DoubleButtonStack}>
            <CreationControllerStart
              onClick={() => {
                setCreationStep(1)
              }}
              isHidden={creationStep !== 2}
              text={"Back"}
              isInverted
            />

            {selectedPolaroid !== polaroidData.length - 1 ? (
              <CreationControllerStart
                onClick={() => {
                  setCreationStep(0)
                  setSelectedPolaroid(selectedPolaroid + 1)
                }}
                isHidden={creationStep !== 2}
                text={"Next Polaroid"}
              />
            ) : (
              <></>
            )}
          </div>
        ) : null}
      </div>
      {/* <CreationSteps steps={creationStep} /> */}

      {showAddToCart ? (
        <div className={styles.ControlsStacker}>
          <CreationControllerStart
            onClick={() => {
              if (product === undefined || sessionID === undefined) return

              ValidateUserToAnon(user)
                .then((realUser) => {
                  return AddSessionToCart(realUser.uid, session, studioID, sessionID, product)
                })
                .then(() => {
                  return nav("/cart")
                })
                .catch((error: any) => {
                  setUploadingText("Error occurred")
                })
            }}
            isHidden={!showAddToCart}
            text={"Add to Cart"}
          />
        </div>
      ) : null}
    </div>
  )
}

interface CreationControllerProps {
  onClick: () => void
  isHidden: boolean
  isInverted?: boolean
  text: string
  className?: string
  disabled?: boolean
}
export const CreationControllerStart: React.FC<CreationControllerProps> = ({
  onClick,
  isHidden,
  isInverted = false,
  text,
  className,
  disabled,
}): JSX.Element => {
  let classNameIn = styles.polaroidButton

  if (isInverted) {
    classNameIn += " " + styles.polaroidButtonMemo
  }

  if (disabled) {
    classNameIn += " " + styles.polaroidButtonDisabled
  }

  if (className !== undefined) {
    classNameIn = className
  }

  if (isHidden) {
    return <></>
  }

  return (
    <div
      className={classNameIn}
      onClick={() => {
        if (disabled) return
        onClick()
      }}
    >
      {text}
    </div>
  )
}

interface UploadVideoProps {
  onFileSelected: (file: File) => void
  isHidden: boolean
  showNext: boolean
  showBack: boolean
  onNexClicked: (a: boolean) => void
  disabled: boolean
}
export const UploadVideo: React.FC<UploadVideoProps> = ({
  onFileSelected,
  isHidden,
  showNext,
  showBack,
  onNexClicked,
  disabled,
}): JSX.Element => {
  const hiddenFileInput = useRef<HTMLInputElement>(null)

  if (isHidden) return <></>
  return (
    <>
      <input
        style={{ display: "none" }}
        ref={hiddenFileInput}
        type="file"
        accept=".mp4"
        onChange={(e) => {
          if (e.target.files !== null) {
            const file = e.target.files[0]
            onFileSelected(file)
          }
          e.target.files = null
          e.target.value = ""
        }}
      />
      <CreationControllerStart
        onClick={() => {
          hiddenFileInput.current?.click()
        }}
        isHidden={false}
        text={"Upload Video"}
        disabled={disabled}
      />
      {showBack || showNext ? (
        <div className={styles.DoubleButtonStack}>
          <CreationControllerStart
            onClick={() => {
              onNexClicked(false)
            }}
            isHidden={!showBack}
            text={"Back"}
            disabled={disabled}
            isInverted
          />
          <CreationControllerStart
            onClick={() => {
              onNexClicked(true)
            }}
            isHidden={!showNext}
            text={"Skip"}
            disabled={disabled}
            isInverted
          />
        </div>
      ) : null}
    </>
  )
}

interface UploadPictureProps {
  onFileSelected: (file: File | Blob) => void
  onSlide: (time: number) => void
  videoUrl: string
  isHidden: boolean
  showNext: boolean
  showBack: boolean
  onNexClicked: (a: boolean) => void
  disabled: boolean
  fileRatio: number | undefined
}

export const UploadPicture: React.FC<UploadPictureProps> = ({
  onFileSelected,
  videoUrl,
  onSlide,
  isHidden,
  showNext,
  showBack,
  onNexClicked,
  disabled,
  fileRatio = 0.2,
}): JSX.Element | null => {
  const hiddenFileInput = useRef<HTMLInputElement>(null)
  const canvasReg = useRef<HTMLCanvasElement>(null)
  const videoRef = useRef<HTMLVideoElement>(null)
  const [sliderPosition, setSliderPosition] = useState<string>("0")
  const [actualURL, setActualURL] = useState("")

  useEffect(() => {
    const storage = getStorage()

    if (videoUrl.includes("gs://")) {
      const imageStorageRef = ref(storage, videoUrl)
      getBlob(imageStorageRef).then((blob) => {
        const imageURL = URL.createObjectURL(blob)
        setActualURL(imageURL)
        if (videoRef.current) {
          videoRef.current.src = imageURL
          videoRef.current.pause()
        }
      })
    } else {
      setActualURL(videoUrl)
      if (videoRef.current) {
        videoRef.current.src = videoUrl
        videoRef.current.play()
      }
    }
  }, [videoUrl])

  function DrawSelectedResource(image: CanvasImageSource, width: number, height: number, cropWith: number, cropHeight: number) {
    canvasReg.current!.width = cropWith
    canvasReg.current!.height = cropHeight
    canvasReg
      .current!.getContext("2d")!
      .drawImage(image, width / 2 - cropWith / 2, height / 2 - cropHeight / 2, cropWith, cropHeight, 0, 0, cropWith, cropHeight)
    canvasReg.current!.toBlob((blob: Blob | null) => {
      if (blob === null) {
        return
      }
      onFileSelected(blob)
    })
  }

  function CropSelectedVideoResource(
    resource: CanvasImageSource,
    ratio: number,
    videoImageWith: number,
    videoImageHeight: number
  ) {
    const widthTopRatio = videoImageWith / videoImageHeight
    const heightTopRatio = videoImageHeight / videoImageWith

    let useWidth = widthTopRatio < heightTopRatio
    const highEnough = videoImageWith / ratio > videoImageHeight
    useWidth = useWidth && !highEnough

    let actualHeight = 0
    let actualWidth = 0
    if (!useWidth) {
      actualHeight = videoImageHeight
      actualWidth = videoImageHeight * ratio
    } else {
      actualHeight = videoImageWith / ratio
      actualWidth = videoImageWith
    }

    // console.log(!useWidth, highEnough, videoImageWith, videoImageHeight, actualWidth, actualHeight)

    DrawSelectedResource(resource, videoImageWith, videoImageHeight, actualWidth, actualHeight)
  }

  if (isHidden) return null

  return (
    <>
      <input
        style={{ display: "none" }}
        ref={hiddenFileInput}
        type="file"
        accept=".png, .jpeg, .jpg"
        onChange={(e) => {
          if (e.target.files !== null) {
            const file = e.target.files[0]

            var img = new Image()

            img.onload = function () {
              var height = img.height
              var width = img.width
              CropSelectedVideoResource(img, fileRatio, width, height)
              // code here to use the dimensions
            }

            img.src = URL.createObjectURL(file)
          }
          e.target.files = null
          e.target.value = ""
        }}
      />

      <div className={styles.VideoFibreSlide}>
        <video
          width="100%"
          height="100%"
          controls={false}
          autoPlay={true}
          src={actualURL}
          loop
          playsInline
          muted
          preload={"auto"}
          ref={videoRef}
        />
      </div>

      <canvas id="canvas" ref={canvasReg} className={styles.canvasPreview} style={{ display: "none" }}></canvas>

      <CreationSliderController
        onSlide={(e) => {
          if (videoRef.current === null) {
            console.error("DOM VIDEO NOT PRESENT YET")
            return
          }

          const position = parseInt(e) / 100
          const totalTime = videoRef.current?.duration ?? 0
          const selectedTime = totalTime * position

          let selectedTimeFrame = 0
          if (isFinite(selectedTime)) {
            selectedTimeFrame = selectedTime
          }

          videoRef.current.currentTime = selectedTimeFrame
          videoRef.current.pause()
          setSliderPosition(e)
          onSlide(selectedTimeFrame)
        }}
        isHidden={false}
        sliderPosition={sliderPosition}
      />

      <CreationControllerStart
        onClick={() => {
          const position = parseInt(sliderPosition) / 100
          const totalTime = videoRef.current?.duration ?? 0
          const selectedTime = totalTime * position
          let selectedTimeFrame = 0
          if (isFinite(selectedTime)) {
            videoRef.current!.currentTime = selectedTime
            selectedTimeFrame = selectedTime
          }
          if (videoRef.current === null) {
            console.error("DOM VIDEO NOT PRESENT YET")
            return
          }
          videoRef.current!.currentTime = selectedTimeFrame
          // use this to calculate how to do the cropping
          CropSelectedVideoResource(videoRef.current, fileRatio, videoRef.current!.videoWidth, videoRef.current!.videoHeight)
          // DrawSelectedResource(videoRef.current, videoRef.current!.videoWidth, videoRef.current!.videoHeight)
        }}
        disabled={disabled}
        isHidden={false}
        text={"Use this Frame"}
      />

      <u
        onClick={() => {
          hiddenFileInput.current?.click()
        }}
      >
        select from computer
      </u>

      <div className={styles.DoubleButtonStack}>
        <CreationControllerStart
          onClick={() => {
            onNexClicked(false)
          }}
          isHidden={!showBack}
          text={"Back"}
          disabled={disabled}
          isInverted
        />
        <CreationControllerStart
          onClick={() => {
            onNexClicked(true)
          }}
          isHidden={!showNext}
          text={"Skip"}
          disabled={disabled}
          isInverted
        />
      </div>
    </>
  )
}

interface CreationSliderControllerProps {
  onSlide: (e: string) => void
  sliderPosition: string
  isHidden: boolean
}

export const CreationSliderController: React.FC<CreationSliderControllerProps> = ({
  onSlide,
  sliderPosition,
  isHidden,
}): JSX.Element => {
  if (isHidden) {
    return <></>
  }

  return (
    <div className={styles.sliderContainer}>
      <input
        type="range"
        min="1"
        max="100"
        value={sliderPosition}
        className={styles.slider}
        id="myRange"
        onInput={(e) => {
          const currentSliderPosition = e.currentTarget.value
          onSlide(currentSliderPosition)
        }}
      ></input>
    </div>
  )
}

// interface IntroBodyProps {
//   session: StudioSession | undefined
//   onClick: () => void
// }

// export const IntroBody: React.FC<IntroBodyProps> = ({ session, onClick }): JSX.Element => {
//   return (
//     <div className={styles.IntroBodyProps}>
//       <h1> Create your Revivar Polaroid</h1>
//       <span> A custom polaroid print with an AR Video</span>
//       <CreationControllerStart onClick={onClick} isHidden={session !== undefined} text={"Start Designing"} />
//     </div>
//   )
// }
